import React from 'react'
import { Spinner } from '@chakra-ui/react'
import { delay } from 'lodash'
import axios from 'lib/axios'
import { renowalkUrl } from 'lib/config'
import { ActionButton } from 'components/TailwindUIToolkit'

export const DownloadPdfButtonWithLoaderAnimation = (renowalkId?: number | null) => {
  const [waitingForPdf, setWaitingForPdf] = React.useState(false)
  const [pdf, setPdf] = React.useState<{
    name: string
    url: string
  } | null>(null)

  React.useEffect(() => {
    axios({
      method: 'get',
      url: `/properties/renowalk_docs/${renowalkId}`
    }).then((response) => {
      const materialAndLaborPdfUrl = response.data['MATERIAL AND LABOR PDF']
      const materialPdfUrl = response.data['SCOPE AND PHOTO PDF']
      const photoPdfUrl = response.data['PHOTO ONLY PDF']
      if (!response.data) {
        // Some error has happened
        // We are not being proactive here since most likely
        // the user will not be affected by this since they likely didn't need the data
        // that would have been returned (i.e. you usually don't download a pdf when you check the
        // construction tab )
        return
      }
      if (materialAndLaborPdfUrl) {
        setPdf({
          name: 'Download Pdf',
          url: materialAndLaborPdfUrl
        })
      } else if (materialPdfUrl) {
        setPdf({
          name: 'Download Pdf: No Labor',
          url: materialPdfUrl
        })
      } else if (photoPdfUrl) {
        setPdf({
          name: 'Download Pdf: Photos Only',
          url: photoPdfUrl
        })
      } else {
        // Do nothing
        // There aren't any pdf urls available to the API. It is unsure why this happens for some homes
        // 590796 is a sample Renowalk property_id (construction_scopes renowalk_id) where there
        // are pdfs available via renowalk, but not their api
      }
    })
  }, [])

  if (!renowalkId || !pdf) {
    return 'No PDFs imported; Verify In Renowalk'
  }

  if (waitingForPdf) {
    return <Spinner />
  }

  return (
    <ActionButton
      onClick={async () => {
        setWaitingForPdf(true)
        await downloadPdf(pdf?.url)
        // arbitrary wait used because the above resolves when this React App has downloaded the data
        // but before the file is downloaded completely to client's computer
        delay(() => setWaitingForPdf(false), 500)
      }}
    >
      {pdf?.name}
    </ActionButton>
  )
}

export const downloadPdf = async (renowalkPdfUrl: string) => {
  try {
    const link = document.createElement('a')
    link.href = renowalkPdfUrl
    // Append to html link element page
    document.body.appendChild(link)
    // Start download
    link.click()
    // Clean up and remove the link
    link?.parentNode?.removeChild(link)
  } catch (error) {
    alert(
      'Unable to download the PDF - This may be caused by error with our integration with Renowalk and will be resolved in later iterations.'
    )
  }
}

export const linkButtonToRenowalk = (renowalkId?: number | null) =>
  renowalkId ? (
    <ActionButton
      onClick={() => {
        window.open(`${renowalkUrl}/property/${renowalkId}/budget`, '_blank')
      }}
    >
      Open Renowalk
    </ActionButton>
  ) : (
    'No Renowalk Project Found'
  )
