import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import axios from 'lib/axios'

function UploadContainer({
  children,
  uploadPath = '',
  hideCss = true,
  onChange = () => null,
  onSuccess = () => null
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState(null)

  function clearData() {
    if (file) {
      URL.revokeObjectURL(file.preview)
    }
  }

  const resetState = () => {
    setFile(null)
    setIsLoading(false)
    setError(null)
    setDownloadUrl(null)
  }

  useEffect(() => {
    resetState()
    return () => clearData()
  }, [uploadPath])

  useEffect(() => {
    onChange({
      isLoading,
      file,
      error,
      uploadPath,
      downloadUrl,
      resetState
    })
  }, [isLoading, file, error, downloadUrl])

  const onDrop = async (acceptedFiles) => {
    resetState()
    setIsLoading(true)

    const acceptedFile = acceptedFiles[0]

    setFile(
      Object.assign(acceptedFile, {
        preview: URL.createObjectURL(acceptedFile)
      })
    )

    if (uploadPath) {
      const { data } = await axios.get(
        `documents/signed_upload_url?is_admin=true&file_name=${uploadPath}`
      )

      await axios.put(data.upload_url, acceptedFile, {
        headers: { 'Content-Type': acceptedFile.type }
      })

      const downloadUrl = data.download_url

      setIsLoading(false)
      setDownloadUrl(downloadUrl)
      onSuccess(downloadUrl)
    }
  }

  return (
    <Dropzone multiple={false} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={hideCss ? '' : `dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
          >
            <input {...getInputProps()} />
            {React.cloneElement(children, {
              isLoading,
              downloadUrl,
              remove: resetState,
              error
            })}
          </div>
        )
      }}
    </Dropzone>
  )
}

UploadContainer.propTypes = {
  children: PropTypes.node,
  hideCss: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  uploadPath: PropTypes.string.isRequired
}

export default memo(UploadContainer)
