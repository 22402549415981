import React, { FunctionComponent, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import type { HomesGridQuery } from 'graphql/generated'
import { GridApi, GridOptions, GridReadyEvent, ColDef } from 'ag-grid-community'
import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-material.css'
import 'components/TailwindUIToolkit/Grid/custom-theme.css'
import { useHistory } from 'react-router-dom'
import type { StatusFilter } from 'screens/Homes/HomesList'

export type PropertyGridDataType = HomesGridQuery['homes_list'][number]
const gridOptions: GridOptions<PropertyGridDataType> = {
  domLayout: 'normal',
  sideBar: true,
  defaultColDef: {
    minWidth: 40,
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    getQuickFilterText: () => ''
  },
  pagination: true,
  paginationPageSize: 18
}

interface GridProps {
  rowData: HomesGridQuery['homes_list']
  onGridReady: (evt: GridReadyEvent<PropertyGridDataType>) => void
  statusFilter?: StatusFilter
  columns: ColDef<PropertyGridDataType>[]
}

export const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({
  onGridReady,
  columns,
  rowData,
  statusFilter
}) => {
  const history = useHistory()
  const [gridApi, setGridApi] = useState<GridApi<PropertyGridDataType>>()

  const gridSetup = (evt: GridReadyEvent<PropertyGridDataType>) => {
    setGridApi(evt.api)
    onGridReady(evt)
  }

  useEffect(() => {
    // this clears any previous filters
    gridApi?.setFilterModel({})
    statusFilter &&
      // this sets the filter to the selected status
      gridApi?.setFilterModel({
        [statusFilter.statusType]: { filter: [statusFilter.statusName] }
      })
  }, [gridApi, statusFilter])

  useEffect(() => {
    if (gridApi && rowData) {
      // if row data prop changes, need to update programatically
      try {
        gridApi.setRowData(rowData)
        gridApi.onFilterChanged()
      } catch {
        console.log('ran into an error drawing the grid, you may need to refresh the page')
      }
    }
  }, [gridApi, rowData])

  return (
    <div className='ag-theme-material' style={{ height: '74vh' }}>
      <AgGridReact<PropertyGridDataType>
        gridOptions={{
          ...gridOptions,
          context: {
            navigateToPage: (url: string) => history.push(url)
          }
        }}
        columnDefs={columns}
        rowData={rowData}
        onGridReady={gridSetup}
        onRowDoubleClicked={(evt) => {
          history.push(`/home/${evt.data?.id}`)
        }}
      />
    </div>
  )
}
