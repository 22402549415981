import styled, { css, keyframes } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import * as c from './colors'
import {
  ChevronRight,
  ChevronDown,
  Home,
  XCircle,
  Check,
  CheckCircle,
  QuestionMarkCircle,
  Star,
  Plus as SolidPlus,
  Trash,
  XMark as X,
  MapPin as LocationMarker,
  Calendar,
  Link,
  ExclamationCircle,
  Clock
} from '@styled-icons/heroicons-solid'
import {
  AdjustmentsHorizontal as Adjustments,
  ArrowUp as ArrowSmUp,
  ArrowDown as ArrowSmDown,
  Plus,
  Star as OutlineStar
} from '@styled-icons/heroicons-outline'

import { DotsThreeVertical } from '@styled-icons/entypo/DotsThreeVertical'
import { DotSingle } from '@styled-icons/entypo/DotSingle'

export const hoverTransition = (hoverColor: string) => css`
  :hover {
    background-color: ${hoverColor};
  }

  transition: background-color 0.25s linear;
`

export const DropDownIcon = styled(DotsThreeVertical)`
  height: 20px;
  color: ${c.GRAY_400};
  :hover {
    cursor: pointer;
    color: ${c.GRAY_500};
  }
`

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 130px;
`

export const DropDownMenu = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 130px;
  visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  position: absolute;
  margin-top: 2rem;
  z-index: 2;

  background: white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 1rem;
  gap: 1rem;

  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: ${c.GRAY_700};
  line-height: 20px;
`
export const DropDownMenuItem = styled.div`
  :hover {
    cursor: pointer;
    font-weight: 500;
  }
`

const containerBoxShadow = css`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
`

export const SectionContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${c.GRAY_200};
  border-radius: 8px;
  ${containerBoxShadow}
`

export const SectionContent = styled.div<{ size?: string; borders?: boolean }>`
  padding: ${({ borders }) => (borders ? `0` : `1.5rem`)};
  max-height: ${({ size }) => (size === 'large' ? `30rem` : `16rem`)};
  overflow-y: auto;
  // scrollbar-gutter: stable;
`

export const SectionHeader = styled.div`
  border-bottom: 1px solid ${c.GRAY_200};
  padding: 1rem 1.5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const BreadcrumbItem = styled.a`
  color: ${c.GRAY_500};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;

  :hover {
    color: ${c.GRAY_200};
  }

  margin-right: 1rem;

  // if it is the first element in the list, don't use margin-left
  ${({ first }: { first?: boolean }) =>
    !first &&
    css`
      margin-left: 1rem;
    `}
`

export const StyledChevronRight = styled(ChevronRight)`
  color: ${c.GRAY_500};
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
`

export const StyledChevronDown = styled(ChevronDown)`
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
`

export const StyledHome = styled(Home)`
  color: ${c.GRAY_500};
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
`

export const StyledOL = styled.ol`
  display: flex;
  align-items: center;
`

export const StyledTable = styled.table`
  width: 100%;
  position: relative;
`

export const StyledTableHeader = styled.thead`
  background-color: ${c.GRAY_50};
  position: sticky;
  top: 0;
  z-index: 2;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -1px;
    border-bottom: 1px ${c.GRAY_200} solid;
  }
`

export const StyledTableHeadData = styled.th`
  color: ${c.GRAY_500};
  padding: 1rem 0rem 1rem 1.5rem;
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

export const StyledTableData = styled.td<{ center?: boolean }>`
  color: ${c.GRAY_500};
  padding: 1rem 0rem 1rem 1.5rem;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  vertical-align: ${({ center }) => (center ? `center` : `top`)};
`

export const StyledTableRow = styled.tr`
  border-bottom: 1px ${c.GRAY_200} solid;
  &:last-child {
    border: none;
  }
`

export const StyledTableLink = styled.a`
  color: ${c.GRAY_900};
  font-weight: 500;
  :hover {
    cursor: pointer;
  }
`

export const BreadcrumbItemContainer = styled.div`
  display: flex;
  align-items: center;
`
export const TimelineEvent = styled.div`
  width: 100%;
  height: fit-content;

  background: #ffffff;
  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

const ButtonIconCSS = css`
  height: 1rem;
  width: 1rem;
  color: ${c.GRAY_500};
`

export const UpArrowIcon = styled(ArrowSmUp)`
  ${ButtonIconCSS}
`

export const DownArrowIcon = styled(ArrowSmDown)`
  ${ButtonIconCSS}
`

export const AdjustmentsIcon = styled(Adjustments)`
  ${ButtonIconCSS}
`

export const PlusIcon = styled(Plus)`
  height: 1.1rem;
  width: 1.1rem;
`

export const FilterModal = styled.div<{ isOpen: boolean }>`
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  z-index: 3;
  border: 1px solid ${c.GRAY_200};
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: white;

  /* slide open transition */
  height: fit-content;
  visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  max-height: ${({ isOpen }) => (isOpen ? `500px` : `0`)};
  transition: max-height 0.25s ease-in-out;
  overflow-y: hidden;

  ${containerBoxShadow}
`
export const XIcon = styled(X)`
  height: 18px;
  width: 18px;
  color: ${c.GREEN_500};
  stroke: ${c.GREEN_500};
  stroke-width: 1;
`

export const RedXIcon = styled(X)`
  height: 1rem;
  width: 1rem;
  color: ${c.RED_500};
  stroke: ${c.RED_500};
  stroke-width: 0.5;
`

export const GrayCalendar = styled(Calendar)`
  height: 1.25rem;
  width: 1.25rem;
  color: ${c.GRAY_400};
`

export const AppLink = styled(Link)`
  height: 1.25rem;
  width: 1.25rem;
  color: ${c.GRAY_400};
`

export const GreenCheck = styled(Check)`
  height: 1rem;
  width: 1rem;
  color: ${c.GREEN_500};
`

export const SuccessToastSubContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const SuccessToast = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
  bottom: 5%;
  height: 52px;

  padding: 16px;
  border-radius: 6px;
  background-color: ${c.GREEN_50};
  min-width: 350px;

  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  color: ${c.GREEN_800};
  white-space: nowrap;
  line-height: 20px;

  /* slide open transition */
  right: ${({ isOpen }) => (isOpen ? `10%` : `-60%`)};
  visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  transition: all 0.6s ease-in-out;

  ${containerBoxShadow}
`

export const Modal = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid ${c.GRAY_200};
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
  gap: 1rem;
  max-width: 400px;

  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  color: ${c.GRAY_900};

  /* slide open transition */
  top: ${({ isOpen }) => (isOpen ? `40%` : `150%`)};
  visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  transition: all 0.5s ease-in-out;

  ${containerBoxShadow}
`

export const ModalSubText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${c.GRAY_500};
  user-select: none;
`

export const ErrorModalXCircle = styled(XCircle)`
  height: 48px;
  weight: 48px;
  color: ${c.RED_100};
`

export const FilterCheckButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: white;
  border: none;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  align-items: center;
  ${hoverTransition(c.GRAY_50)}
`

export const FilterApplyButton = styled(FilterCheckButton)`
  justify-content: center;
  margin-bottom: 0;
  background-color: ${c.GRAY_200};
  :hover {
    background-color: ${c.GRAY_500};
  }
`

export const FilterInput = styled.input`
  margin-left: 1rem;
`

export const FilterName = styled.span`
  font-size: 14px;
  font-family: 'Inter';
  color: black;
`

// Shared Components
export const ErrorMessage = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 1.5rem;
  color: ${c.RED_400};
`

export const SmallBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const DetailsContainer = styled.div<{ borders?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  padding: ${({ borders }) => (borders ? '1rem 1.5rem' : `0`)};
  gap: ${({ borders }) => (borders ? '.5rem' : `0`)};
  border-bottom: ${({ borders }) => (borders ? `1px ${c.GRAY_200} solid` : `none`)};
  &:last-child {
    border: none;
  }
`

export const DetailHeading = styled.p`
  color: ${c.GRAY_500};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.25rem;
`

export const DetailText = styled.div`
  color: ${c.GRAY_900};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`

export const YesText = styled.div`
  color: ${c.GREEN_600};
`

export const SmallCheckCircle = styled(CheckCircle)`
  width: 16px;
  height: 16px;
  color: ${c.GREEN_400};
  margin-right: 0.25rem;
`

const PropertiesIconCSS = css`
  width: 16px;
  height: 16px;
  color: ${c.GRAY_500};
  margin-right: 0.5rem;
`

export const StyledLocationMarker = styled(LocationMarker)`
  ${PropertiesIconCSS}
`

export const StyledPropertyHome = styled(Home)`
  ${PropertiesIconCSS}
`

export const NoText = styled.div`
  color: ${c.RED_600};
`

export const SmallXCircle = styled(XCircle)`
  width: 16px;
  height: 16px;
  color: ${c.RED_400};
  margin-right: 0.25rem;
`

export const NAText = styled.div`
  color: ${c.GRAY_600};
`

export const SmallQuestionCircle = styled(QuestionMarkCircle)`
  width: 16px;
  height: 16px;
  color: ${c.GRAY_400};
  margin-right: 0.25rem;
`
// Personal Details Section

export const DetailBoxWithBottomBorder = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0 0.5rem 1.5rem;
  border-bottom: 1px ${c.GRAY_200} solid;
  &:last-child {
    border: none;
  }
`

export const PropertyBox = styled(DetailBoxWithBottomBorder)`
  align-content: left;
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
`

export const PropertyDetail = styled.div`
  display: flex;
  font-size: 14px;
  padding-top: 0.5rem;
  color: ${c.GRAY_500};
  position: relative;
  float: left;
`

export const InfoHeading = styled.p`
  color: ${c.GRAY_500};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.25rem;
  margin-bottom: 0rem;
  min-width: 30%;
`

export const DetailsText = styled.div`
  color: ${c.GRAY_900};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 2.25rem;
  padding-left: 14px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const EditDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  align-self: center;
  gap: 0.5em;
`

export const EditDetailsWrapper = styled.div<{ invalid?: boolean }>`
  display: flex;
  border: 1px solid ${({ invalid }) => (invalid ? `${c.RED_600}` : `${c.GRAY_300}`)};
  height: 2.25rem;
  border-radius: 6px;
  padding: 8px 8px 9px 11px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  align-items: center;
  gap: 0.25rem;
`

export const EditDetailsInput = styled.input`
  color: ${c.GRAY_900};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border: none;
  width: 100%;
  :focus {
    outline: none;
  }
`

export const InvisibleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`

export const AddText = styled(InvisibleButton)`
  color: ${c.INDIGO_600};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`
export const IconContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0 0.25rem;
`

export const PrimaryStar = styled(Star)`
  height: 20px;
  width: 20px;
  color: ${c.YELLOW_400};
`

export const NonPrimaryStar = styled(OutlineStar)`
  height: 20px;
  width: 20px;
  color: ${c.GRAY_400};
`

export const AddIcon = styled(SolidPlus)`
  height: 16px;
  width: 16px;
  color: ${c.INDIGO_600};
  margin-right: 0.25rem;
`

export const ExclamationIcon = styled(ExclamationCircle)<{ isOn?: boolean }>`
  height: 20px;
  width: 20px;
  color: ${({ isOn }) => (isOn ? `${c.RED_400}` : `${c.GRAY_400}`)};
`

export const TrashIcon = styled(Trash)`
  height: 20px;
  width: 20px;
  color: ${c.GRAY_400};
`

export const ClockIcon = styled(Clock)<{ isOn?: boolean }>`
  height: 20px;
  width: 20px;
  color: ${({ isOn }) => (isOn ? `${c.GRAY_500}` : `${c.GRAY_400}`)};
`

// Badges

export const Badge = styled.div`
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 12px;
  gap: 6px;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  position: relative;
`

export const BadgeDot = styled(DotSingle)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 0;
`

export const GreenBadge = styled(Badge)`
  background: ${c.GREEN_100};
  color: ${c.GREEN_800};
`

export const GreenDot = styled(BadgeDot)`
  color: ${c.GREEN_400};
`

export const YellowBadge = styled(Badge)`
  background: ${c.YELLOW_100};
  color: ${c.YELLOW_800};
`

export const YellowDot = styled(BadgeDot)`
  color: ${c.YELLOW_400};
`

export const OrangeBadge = styled(Badge)`
  background: ${c.ORANGE_50};
  color: ${c.ORANGE_800};
`

export const OrangeDot = styled(BadgeDot)`
  color: ${c.ORANGE_400};
`

export const RedBadge = styled(Badge)`
  background: ${c.RED_100};
  color: ${c.RED_800};
`

export const RedDot = styled(BadgeDot)`
  color: ${c.RED_400};
`

export const BlueBadge = styled(Badge)`
  background: ${c.BLUE_100};
  color: ${c.BLUE_800};
`

export const BlueDot = styled(BadgeDot)`
  color: ${c.BLUE_400};
`

export const GrayBadge = styled(Badge)`
  background: ${c.GRAY_100};
  color: ${c.GRAY_800};
`

export const GrayDot = styled(BadgeDot)`
  color: ${c.GRAY_400};
`

export const PurpleBadge = styled(Badge)`
  background: ${c.INDIGO_100};
  color: ${c.INDIGO_500};
`

export const PurpleDot = styled(BadgeDot)`
  color: ${c.INDIGO_600};
`

export const BadgeContent = styled.div<{ dot?: boolean }>`
  margin-left: ${({ dot }) => (dot ? `1rem` : `0`)};
`

// From the Timelines folder

export const IconBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  /* box-shadow: 0 0 0 0.5rem white; */
  ${({ color }) => `background-color: ${color}`}
`

export const Line = styled.span`
  position: absolute;
  top: 1rem;
  left: 1.85rem;
  margin-left: -1px;
  background-color: #e5e7eb;
  width: 0.125rem;
  height: 100%;
`

export const Timestamp = styled.div`
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  white-space: nowrap;
`

export const EventContent = styled.div`
  display: flex;
  padding-top: 0.375rem;
  margin-left: 1rem;
  flex: 1 1 0%;
  justify-content: space-between;
  min-width: 0;
`

export const MessageText = styled.p`
  width: 100%;
  color: ${c.GRAY_500};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`

export const BoldMessageText = styled.p`
  width: 100%;
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 4px;
`

export const DescriptionText = styled.p`
  width: 100%;
  color: #6b7280;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`

export const EventContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: 0.75rem;
  margin-left: 0.875rem;
`

export const EventOuter = styled.div`
  cursor: pointer;
  position: relative;
  padding: 1rem 1rem 1rem 0;
  border-radius: 8px;
  transition: background-color 0.25s linear;
  :hover {
    background-color: ${c.GRAY_50};
  }
`

export const TimelineListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${c.GRAY_800};
  font-weight: 600;

  :hover {
    text-decoration: none;
  }
`

export const StyledRouterLink = styled(RouterLink)`
  text-decoration: underline;
  color: ${c.GRAY_800};
  font-weight: 500;

  :hover {
    text-decoration: none;
  }
`

export const EventSubheading = styled.p`
  color: #9ca3af;
  text-transform: uppercase;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  width: 100%;
  margin-bottom: 8px;
`

export const Subsection = styled.div`
  margin-top: 1rem;
`

export const InlineBold = styled.span`
  font-weight: 600;
`

export const InlineBoldClickable = styled.a`
  font-weight: 600;
  color: ${c.GRAY_500};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeIn = styled.span`
  animation: ${fadeIn} 0.25s linear;
`

// Lead Group

export const LeadGroupUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const SubsectionTitleLinkCSS = css`
  color: ${c.GRAY_900};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  :hover {
    color: ${c.BLUE_600};
  }
`

export const SubsectionTitleLink = styled.a`
  ${SubsectionTitleLinkCSS}
`

export const SubsectionTitleRouterLink = styled(RouterLink)`
  ${SubsectionTitleLinkCSS}
`

export const LightDetailText = styled.div`
  color: ${c.GRAY_500};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

// Payment Methods Section

export const PaymentMethodItem = styled.div`
  border-color: ${c.GRAY_200};
  border-top-width: 1px;
  border-top-style: solid;
  padding: 1.5rem;

  &:first-child {
    border: none;
  }
`

export const PaymentMethodHeader = styled.div`
  display: flex;
  flex-direction: flex-row;
  justify-content: space-between;
`

export const PaymentMethodRow = styled.div`
  display: flex;
  flex-direction: flex-row;
  margin-bottom: 0.25rem;
`

export const PaymentMethodProp = styled(LightDetailText)`
  flex: 1;
`

export const PaymentMethodValue = styled(DetailText)`
  flex: 1;
`
