"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TICKET_EXTERNAL_SOURCES = exports.TICKET_TYPES = void 0;
var TICKET_TYPES;
(function (TICKET_TYPES) {
    TICKET_TYPES["TURN"] = "turn";
    TICKET_TYPES["DISPO"] = "dispo";
    TICKET_TYPES["REHAB"] = "rehab";
    TICKET_TYPES["MAINTAINENCE"] = "maintainence";
})(TICKET_TYPES || (exports.TICKET_TYPES = TICKET_TYPES = {}));
var TICKET_EXTERNAL_SOURCES;
(function (TICKET_EXTERNAL_SOURCES) {
    TICKET_EXTERNAL_SOURCES["LATCHEL"] = "latchel";
})(TICKET_EXTERNAL_SOURCES || (exports.TICKET_EXTERNAL_SOURCES = TICKET_EXTERNAL_SOURCES = {}));
