import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import snakeCase from 'lodash/snakeCase'
import {
  Stack,
  Heading,
  Divider,
  Text,
  HStack,
  Link,
  IconButton,
  Input,
  Center,
  Spinner,
  useToast,
  Button,
  Select,
  Checkbox,
  FormLabel,
  FormControl
} from '@chakra-ui/react'
import {
  PhoneIcon,
  EnvelopeIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/20/solid'
import { markets } from '@homevest/utils'

import {
  useCreateVendorMarketsMutation,
  useDeleteVendorMarketsMutation,
  useMarketsFromNamesQuery,
  useUpdateVendorDetailsMutation,
  useUpdateVendorLatchelIdMutation,
  VendorDetailsByIdQuery
} from 'graphql/generated'
import {
  ContentSectionCard,
  LeftAlignedDataList,
  StarRatingDisplay
} from 'components/TailwindUIToolkit'
import { GrayBadge } from 'components/TailwindUIToolkit/StyledComponents'
import { formatPhoneFromDatabase } from 'lib/formatting'

const { OPERATIONAL_MARKET_NAMES } = markets

type Vendor = NonNullable<VendorDetailsByIdQuery['vendors_by_pk']>
type LatchelVendorUser = Vendor['latchel_vendor_users'][number]

const VendorInformation = ({ vendor, onUpdate }: { vendor: Vendor; onUpdate: () => void }) => {
  const latchelCategories = (vendor.latchel_vendor_categories || [])
    .map((category) => category.latchel_category.name)
    .join(', ')

  // If there are already vendor users associated with this Latchel ID,
  // updating the Latchel ID would break the FK constraint on latchel_vendor_users
  // and possibly mess up data that was synced previously. We should really
  // only edit the ID if there is no Latchel data associated with the vendor yet.
  const canUpdateLatchelId = vendor.latchel_vendor_users.length === 0

  return (
    <Stack spacing={5}>
      <VendorDetails onUpdate={onUpdate} vendor={vendor} />
      <ContentSectionCard title='Latchel Info'>
        <div className='px-6 py-3'>
          {vendor.latchel_id && (
            <div className='mb-3'>
              <Link
                isExternal
                href={`https://app.latchel.com/admin/vendor-company/${vendor.latchel_id}`}
              >
                <HStack>
                  <Text>View in Latchel</Text>
                  <ArrowTopRightOnSquareIcon className='h-4 w-4' />
                </HStack>
              </Link>
            </div>
          )}
          <LeftAlignedDataList
            data={[
              {
                label: 'Vendor Company ID',
                value: (
                  <EditableLatchelId
                    vendorId={vendor.id}
                    latchelId={vendor.latchel_id}
                    canEdit={canUpdateLatchelId}
                    onEditSubmit={onUpdate}
                  />
                ),
                labelFormatter: (label) => label
              },

              { label: 'Categories', value: latchelCategories || '-' }
            ]}
          />
          <Divider />
          <Heading size='sm' fontWeight={'semibold'} py='3'>
            Users
          </Heading>
          <Stack spacing={3}>
            {!vendor.latchel_vendor_users.length && <Text>No users found</Text>}
            {vendor.latchel_vendor_users.map((latchelVendorUser) => (
              <LatchelUserDisplay key={latchelVendorUser.id} {...latchelVendorUser} />
            ))}
          </Stack>
        </div>
      </ContentSectionCard>
    </Stack>
  )
}

const EditableLatchelId = ({
  vendorId,
  latchelId,
  canEdit,
  onEditSubmit
}: {
  vendorId: string
  latchelId?: number | null
  canEdit?: boolean
  onEditSubmit: () => void
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedLatchelId, setEditedLatchelId] = useState(String(latchelId ?? ''))

  const toast = useToast()
  const [{ fetching }, updateLatchelId] = useUpdateVendorLatchelIdMutation()

  const handleSubmit = async () => {
    const result = await updateLatchelId({
      latchelId: Number(editedLatchelId),
      vendorId
    })

    if (result.error) {
      const errorMessage = result.error.message.includes('Uniqueness violation')
        ? 'This Latchel ID is already associated with another vendor'
        : result.error.message

      toast({
        title: 'Error updating Latchel ID',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
      return
    }

    onEditSubmit()
    toast({
      title: 'Latchel ID Updated!',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right'
    })
    setIsEditing(false)
  }

  const handleCancel = () => {
    setEditedLatchelId(String(latchelId))
    setIsEditing(false)
  }

  if (fetching) {
    return (
      <Center>
        <Spinner size='sm' color='teal' />
      </Center>
    )
  }

  if (!isEditing) {
    return (
      <HStack>
        <Text>{latchelId || '-'}</Text>
        {canEdit && (
          <IconButton
            size='sm'
            variant='ghost'
            aria-label='Edit Latchel ID'
            icon={<PencilIcon className='h-4 w-4' />}
            onClick={() => setIsEditing(true)}
          />
        )}
      </HStack>
    )
  }

  return (
    <HStack>
      <Input
        size='sm'
        value={editedLatchelId}
        type='number'
        onChange={(evt) => setEditedLatchelId(evt.target.value)}
      />
      <IconButton
        size='sm'
        aria-label='Save'
        colorScheme={'teal'}
        variant='outline'
        icon={<CheckIcon className={'h-4 w-4'} />}
        onClick={handleSubmit}
      />
      <IconButton
        size='sm'
        aria-label='Cancel'
        colorScheme={'red'}
        variant='outline'
        icon={<XMarkIcon className={'h-4 w-4'} />}
        onClick={handleCancel}
      />
    </HStack>
  )
}

const LatchelUserDisplay = (latchelVendorUser: LatchelVendorUser) => {
  const contactPhone = latchelVendorUser.contact_phone
  const contactEmail = latchelVendorUser.contact_email

  return (
    <div className='rounded-md border bg-slate-100 p-2'>
      <div className='flex flex-col gap-1'>
        <Link
          mb={1}
          href={`https://app.latchel.com/admin/contractor/${latchelVendorUser.latchel_id}`}
          isExternal
        >
          <Text fontWeight={'semibold'}>{latchelVendorUser.name}</Text>
        </Link>
        <HStack>
          <PhoneIcon className='h-4 w-4' />
          {contactPhone ? (
            <Link href={`tel:${contactPhone}`}>
              <Text>{formatPhoneFromDatabase(contactPhone)}</Text>
            </Link>
          ) : (
            <Text>-</Text>
          )}
        </HStack>
        <HStack>
          <EnvelopeIcon className='h-4 w-4' />
          {contactEmail ? (
            <Link href={`mailto:${contactEmail}`}>
              <Text>{contactEmail}</Text>
            </Link>
          ) : (
            <Text>-</Text>
          )}
        </HStack>
      </div>
    </div>
  )
}

type VendorDetailsEditState = {
  type: string
  marketNames: string[]
  rating: number | null
}

const VendorDetails = ({ vendor, onUpdate }: { vendor: Vendor; onUpdate: () => void }) => {
  const [editing, setEditing] = useState<boolean>(false)
  const [editState, setEditState] = useState<VendorDetailsEditState>({
    type: vendor.type,
    marketNames: vendor.vendor_markets.map(({ market }) => market.name),
    rating: vendor.internal_rating || null
  })

  const [{ fetching: fetchingVendorDetails }, updateVendorDetails] =
    useUpdateVendorDetailsMutation()
  const [{ data: allActiveMarkets, fetching: fetchingMarkets }] = useMarketsFromNamesQuery({
    variables: { marketNames: OPERATIONAL_MARKET_NAMES }
  })
  const [{ fetching: deletingMarkets }, deleteVendorMarkets] = useDeleteVendorMarketsMutation()
  const [{ fetching: addingMarkets }, addVendorMarkets] = useCreateVendorMarketsMutation()

  const fetching = fetchingMarkets || fetchingVendorDetails || deletingMarkets || addingMarkets

  const marketBadges = (
    <HStack flexWrap='wrap' lineHeight='8'>
      {(vendor.vendor_markets || [])
        .filter(({ market }) => OPERATIONAL_MARKET_NAMES.includes(market.name as any))
        .map(({ market }) => (
          <div key={market.id}>
            <GrayBadge>{market.display_name}</GrayBadge>
          </div>
        ))}
    </HStack>
  )

  const typeDisplay = vendor.type ? startCase(vendor.type) : '-'
  const typeEdit = (
    <Input
      value={editState.type}
      onChange={(e) => setEditState((prev) => ({ ...prev, type: e.target.value }))}
    />
  )

  const marketsDisplay = marketBadges || '-'
  const marketsEdit = (
    <Stack>
      {allActiveMarkets?.markets.map(({ display_name, name }) => (
        <FormControl key={name}>
          <FormLabel>
            <HStack gap={2}>
              <Text>{display_name}</Text>
              <Checkbox
                isChecked={editState.marketNames.includes(name)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEditState((prev) => ({ ...prev, marketNames: [...prev.marketNames, name] }))
                  } else {
                    setEditState((prev) => ({
                      ...prev,
                      marketNames: prev.marketNames.filter((marketName) => marketName !== name)
                    }))
                  }
                }}
              />
            </HStack>
          </FormLabel>
        </FormControl>
      ))}
    </Stack>
  )

  const phoneDisplay = vendor.phone ? (
    <Link href={`tel:${vendor.phone}`}>{formatPhoneFromDatabase(vendor.phone)}</Link>
  ) : (
    '-'
  )

  const emailDisplay = vendor.email ? (
    <Link href={`mailto:${vendor.email}`}>{vendor.email}</Link>
  ) : (
    '-'
  )

  const buildiumIdDisplay = vendor.external_id ? (
    <Link
      href={`https://upandup.managebuilding.com/manager/app/maintenance/vendors/${vendor.external_id}/summary`}
      isExternal
    >
      <HStack>
        <Text>{vendor.external_id}</Text>
        <ArrowTopRightOnSquareIcon className='h-4 w-4' />
      </HStack>
    </Link>
  ) : (
    '-'
  )

  const ratingDisplay = <StarRatingDisplay rating={vendor.internal_rating || 0} />
  const ratingEdit = (
    <Select
      isDisabled={fetching}
      value={editState.rating ?? ''}
      onChange={(e) =>
        setEditState((prev) => ({
          ...prev,
          rating: e.target.value ? Number(e.target.value) : null
        }))
      }
    >
      <option value={''}>No rating</option>
      {[1, 2, 3, 4, 5].map((rating) => (
        <option key={rating} value={rating}>
          {rating}
        </option>
      ))}
    </Select>
  )

  const handleEditOn = () => {
    setEditState({
      type: vendor.type,
      marketNames: vendor.vendor_markets.map(({ market }) => market.name),
      rating: vendor.internal_rating || null
    })
    setEditing(true)
  }

  const handleEditOff = () => {
    setEditing(false)
  }

  const handleSave = async () => {
    // Save the editState
    const result = await updateVendorDetails({
      vendorId: vendor.id,
      type: snakeCase(editState.type),
      rating: editState.rating
    })

    const existingVendorMarketIds = result.data?.update_vendors_by_pk?.vendor_markets.map(
      ({ market_id }) => market_id
    )

    const newMarketIds = editState.marketNames.map((marketName) => {
      const market = allActiveMarkets?.markets.find(({ name }) => name === marketName)
      return market?.id
    })

    const marketIdsToDelete = (existingVendorMarketIds || []).filter(
      (marketId) => !newMarketIds?.includes(marketId)
    )
    const marketIdsToAdd = newMarketIds.filter(
      (marketId) => !existingVendorMarketIds?.includes(marketId)
    )

    if (marketIdsToAdd.length || marketIdsToDelete.length) {
      await Promise.all([
        deleteVendorMarkets({
          vendorId: vendor.id,
          marketIds: marketIdsToDelete
        }),
        ...marketIdsToAdd.map((marketId) =>
          addVendorMarkets({
            vendorId: vendor.id,
            marketId
          })
        )
      ])
    }

    onUpdate()
    setEditing(false)
  }

  const editButton = (
    <Button
      colorScheme={editing ? 'purple' : 'gray'}
      variant={editing ? 'solid' : 'outline'}
      onClick={editing ? handleSave : handleEditOn}
    >
      {editing ? 'Save' : 'Edit'}
    </Button>
  )

  const actions = (
    <HStack>
      {editing && (
        <Button variant='outline' onClick={handleEditOff}>
          Cancel
        </Button>
      )}
      {editButton}
    </HStack>
  )

  return (
    <ContentSectionCard title='Details' padding collapsable={false} action={actions}>
      <LeftAlignedDataList
        data={[
          { label: 'Phone', value: phoneDisplay },
          { label: 'Email', value: emailDisplay },
          { label: 'Buildium ID', value: buildiumIdDisplay, labelFormatter: startCase },
          { label: 'Type', value: editing ? typeEdit : typeDisplay },
          { label: 'Rating', value: editing ? ratingEdit : ratingDisplay },
          { label: 'Markets', value: editing ? marketsEdit : marketsDisplay }
        ]}
      />
    </ContentSectionCard>
  )
}

export default VendorInformation
