import React from 'react'
import { useSelector } from 'react-redux'
import {
  Text,
  Stack,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Modal,
  Button,
  Box,
  Select,
  Textarea
} from '@chakra-ui/react'
import {
  useEscalateRentalApplicationMutation,
  useUpupAdminsWithCapabilitiesByCapabilityTypeQuery
} from 'graphql/generated'
import { combineFirstNameLastName } from 'lib/utils'

export const EscalateApplicationModal: React.FC<
  React.PropsWithChildren<{
    rentalApplicationId: string
    isOpen: boolean
    setOpen: (closed: boolean) => void
  }>
> = ({ rentalApplicationId, isOpen, setOpen }) => {
  const [_, escalateRentalApplication] = useEscalateRentalApplicationMutation()
  const adminId = useSelector((state: any) => state.admin.id)

  const [{ data, error }] = useUpupAdminsWithCapabilitiesByCapabilityTypeQuery({
    variables: { capability_type: 'application_manager_user' }
  })
  const [selectedAdminId, setSelectedAdminId] = React.useState<string | null>(null)
  const [escalateReason, setEscalateReason] = React.useState<string>('')

  if (error || data === undefined) {
    return <Text>Could not load admin list</Text>
  }

  return (
    <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Escalate Application</Text>
          <Text fontSize={'sm'} color={'gray.400'} borderBottom={'1px'} borderColor={'gray.300'}>
            Escalates this application to an app manager
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>Assignee</Text>
          <Select
            onChange={(e) => {
              setSelectedAdminId(e.target.value)
            }}
          >
            <option key={'sa_empty_radio'} value={undefined}>
              Select...
            </option>
            {data.admins.map((a) => (
              <option key={a.id} value={a.id}>
                {combineFirstNameLastName(a)}
              </option>
            ))}
          </Select>
          <Text mt={3}>Reason</Text>
          <Textarea value={escalateReason} onChange={(e) => setEscalateReason(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Box mt={'10'} borderTop={'1px'} borderColor={'gray.300'} w={'full'}>
            <Box float={'right'} p={'2'}>
              <Stack direction={'row'}>
                <Button
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme='purple'
                  disabled={selectedAdminId === null}
                  onClick={() => {
                    escalateRentalApplication({
                      assigned_by_admin_id: adminId,
                      rental_application_id: rentalApplicationId,
                      reason: escalateReason,
                      assigned_to_admin_id: selectedAdminId
                    })
                    setOpen(false)
                  }}
                >
                  Escalate
                </Button>
              </Stack>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
