import {
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  ModalFooter,
  Textarea,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'

import {
  Note_Priorities_Enum,
  useEditCreditMutation,
  useUpupInsertNoteMutation
} from 'graphql/generated'
import axios from 'lib/axios'
import { LedgerItem } from 'lib/ledger'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import React, { FC, useState } from 'react'
import { dates } from '@homevest/utils'

interface EditLedgerItemProps {
  ledgerItem: LedgerItem
  onClose: () => void
  isOpen: boolean
  rentalId: string
}

export const EditLedgerItemModal: FC<React.PropsWithChildren<EditLedgerItemProps>> = ({
  ledgerItem,
  rentalId,
  isOpen,
  onClose
}) => {
  const [newAmount, setNewAmount] = useState(ledgerItem.amount)
  const [editReason, setEditReason] = useState<string>()

  const { mutateAsync: editLiability } = useMutation({
    mutationFn: async () => {
      await axios.put(`/admin/rental_liabilities/${ledgerItem.id}`, {
        amount: newAmount,
        date: ledgerItem.date,
        liability_type_id: ledgerItem.liabilityTypeId,
        note: ledgerItem.note,
        rental_id: rentalId
      })
    }
  })
  const [__, deleteOldAndCreateNewCredit] = useEditCreditMutation()
  const [_n, insertNote] = useUpupInsertNoteMutation()
  const admin = useSelector((state: StoreState) => state.admin)

  const handleSubmit = async () => {
    // need to delete the old liability and create a new one with the same data, but different amount
    if (
      !window.confirm(
        `Changing ${ledgerItem.liabilityLabel} ${ledgerItem.type} for ${ledgerItem.date} from ${ledgerItem.amount} to ${newAmount}? Confirm?`
      )
    ) {
      // no-op
      return
    }
    if (ledgerItem.type === 'Charge') {
      await editLiability()
    } else if (ledgerItem.type === 'Credit') {
      await deleteOldAndCreateNewCredit({
        creditId: ledgerItem.id,
        input: {
          amount: newAmount,
          date: ledgerItem.date,
          liability_type_id: ledgerItem.liabilityTypeId,
          note: ledgerItem.note,
          rental_id: rentalId
        }
      })
    }
    let noteText = `Edited ${dates.formatISODate(ledgerItem.date)} ${ledgerItem.liabilityLabel} ${
      ledgerItem.type
    } (${ledgerItem.id}) from $${ledgerItem.amount} -> $${newAmount}`
    if (editReason) {
      noteText += `
      Reason: ${editReason}`
    }

    await insertNote({
      note: noteText,
      category: 'ledger_update',
      created_by_admin_id: admin.id,
      resource_id: rentalId,
      resource_type: 'rentals',
      priority: Note_Priorities_Enum.Default
    })
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit {ledgerItem.type}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Old Amount: {ledgerItem.amount}
          <NumberInput
            onChange={(_, val) => setNewAmount(val)}
            precision={2}
            format={(val) => `$${val}`}
            step={0.01}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormControl>
            <FormLabel>Reason for Edit</FormLabel>
            <Textarea value={editReason} onChange={(evt) => setEditReason(evt.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button type='submit' onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
