import React from 'react'
import {
  Text,
  Stack,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Modal,
  Button,
  Box,
  Select
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import {
  Agent_Lead_Group_Owners_Agent_Type_Enum_Enum,
  useAssignLeadGroupMutation,
  useRentalApplicationManagerAdminRolesQuery,
  useUpupAdminsWithCapabilitiesByCapabilityTypeQuery
} from 'graphql/generated'
import { combineFirstNameLastName } from 'lib/utils'

const RoleInput = React.memo<{ setRole: Function }>(({ setRole }) => {
  const [{ data: agentTypes }] = useRentalApplicationManagerAdminRolesQuery()

  return (
    <>
      <Text mt={'1'} textColor={'gray.600'}>
        Role
      </Text>
      <Select
        onChange={(event) => {
          setRole(event.target.value)
        }}
      >
        <option key={'aam_empty_radio'} value={undefined}>
          Select...
        </option>
        {agentTypes &&
          agentTypes.agent_lead_group_owners_agent_type_enum.sort().map(({ agent_type }) => (
            <option key={agent_type} value={agent_type}>
              {startCase(agent_type)}
            </option>
          ))}
      </Select>
    </>
  )
})

export const AssignApplicationModal: React.FC<
  React.PropsWithChildren<{
    leadGroupId: string
    isOpen: boolean
    setOpen: (closed: boolean) => void
    onSubmit: () => void
  }>
> = ({ leadGroupId, isOpen, setOpen, onSubmit }) => {
  const [_, assignLeadGroup] = useAssignLeadGroupMutation()

  const [{ data, error }] = useUpupAdminsWithCapabilitiesByCapabilityTypeQuery({
    variables: { capability_type: 'application_manager_user' }
  })

  const [selectedAdminId, setSelectedAdminId] = React.useState<string | null>(null)
  const [selectedRole, setSelectedRole] =
    React.useState<Agent_Lead_Group_Owners_Agent_Type_Enum_Enum | null>(null)

  const handleSubmit = async () => {
    await assignLeadGroup({
      adminId: selectedAdminId,
      agentType: selectedRole!,
      leadGroupId
    })
    setOpen(false)
    onSubmit()
  }

  if (error || data === undefined) {
    return <Text>Could not load admin list</Text>
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={() => setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Assign Application</Text>
          <Text fontSize={'sm'} color={'gray.400'} borderBottom={'1px'} borderColor={'gray.300'}>
            Assigns this application
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>Assignee</Text>
          <Select
            onChange={(e) => {
              setSelectedAdminId(e.target.value)
            }}
          >
            <option key={'sa_empty_radio'} value={undefined}>
              Select...
            </option>
            {data.admins.map((a) => (
              <option key={a.id} value={a.id}>
                {combineFirstNameLastName(a)}
              </option>
            ))}
          </Select>
          <RoleInput setRole={setSelectedRole} />
        </ModalBody>
        <ModalFooter>
          <Box mt={'10'} borderTop={'1px'} borderColor={'gray.300'} w={'full'}>
            <Box float={'right'} p={'2'}>
              <Stack direction={'row'}>
                <Button
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme='purple'
                  disabled={selectedAdminId === null || selectedRole === null}
                  onClick={handleSubmit}
                >
                  Assign
                </Button>
              </Stack>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
