export const URL_QUERY_MAP = {
  status: 'status',
  type: 'type',
  date_uploaded: 'created_at',
  uploaded_by: 'uploaded_by',
  name: 'friendly_name',
  resource_notes: 'resource_notes'
}

// todo v2: use utils
export const ACQUISITIONS_DOCUMENT_TYPE_MAP = {
  purchase_sale_agreement: 'Executed Purchase and Sale Agreement',
  purchase_sale_agreement_addendum: 'Addendums',
  sellers_disclosure_notice: `Seller's Disclosure Notice`,
  earnest_money_receipt: 'Earnest Money Receipt',
  commission_agreement: 'Commission Agreement',
  mls_listing_from_purchase_document: 'MLS Listing from Purchase',
  preliminary_title_commitment: 'Prelim Title Commitment',
  acquisition_amendments: 'Amendments',
  acquisition_mutual_release: 'Mutual Release',
  owners_title_policy: `Owner's Title Policy`,
  final_signed_settlement_statement: 'Final Signed Settlement Statement',
  signed_deed: 'Signed Deed'
}

export const CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP = {
  warranty_info: 'Warranty Info',
  approved_bid: 'Approved Bid',
  photo: 'Photo',
  note: 'Note',
  invoice: 'Invoice',
  other: 'Other'
}

// todo v2: use utils
export const STATUS_MAP = {
  draft: 'Draft',
  archive: 'Archive',
  final: 'Final'
}
