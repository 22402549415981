"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_INCOME_SOURCE_DOCUMENT_TYPES = exports.FINANCIAL_DOCUMENT_TYPES = exports.DOCUMENT_TYPES = exports.DOCUMENT_STATUSES = exports.DOCUMENT_EXTERNAL_SOURCES = void 0;
exports.DOCUMENT_EXTERNAL_SOURCES = Object.freeze({
    GOOGLE_CLOUD: 'google_cloud',
    HELLOSIGN: 'hellosign'
});
exports.DOCUMENT_STATUSES = Object.freeze({
    DRAFT: 'draft',
    ARCHIVE: 'archive',
    FINAL: 'final',
    INVALID: 'invalid',
    PENDING_REVIEW: 'pending_review',
    VALID: 'valid'
});
exports.DOCUMENT_TYPES = Object.freeze({
    ACQUISITION_AMENDMENTS: 'acquisition_amendments',
    ACQUISITION_MUTUAL_RELEASE: 'acquisition_mutual_release',
    APPROVED_BID: 'approved_bid',
    APPLICATION_PACKET: 'application_packet',
    ARTICLES_OF_ORGANIZATION: 'articles_of_organization',
    ASSIGNMENT_OF_LEASE_AND_PURCHASE_OPTION_AGREEMENT: 'assignment_of_lease_and_purchase_option_agreement',
    BANK_STATEMENT: 'bank_statement',
    BID: 'bid',
    BUILDIUM_JOURNAL_ENTRY_CSV: 'buildium_journal_entry_csv',
    COMMISSION_AGREEMENT: 'commission_agreement',
    COMPLETION_PHOTO: 'completion_photo',
    CONSENT_TO_ASSIGNMENT_OF_LEASE_AND_PURCHASE_OPTION_AGREEMENT: 'consent_to_assignment_of_lease_and_purchase_option_agreement',
    CREDIT_REPORT: 'credit_report',
    CRIMINAL_REPORT: 'criminal_report',
    EARNEST_MONEY_RECEIPT: 'earnest_money_receipt',
    EVICTION_REPORT: 'eviction_report',
    FINAL_SIGNED_SETTLEMENT_STATEMENT: 'final_signed_settlement_statement',
    HOLDOVER: 'holdover',
    INSPECTION_REPORT: 'inspection_report',
    IDENTITY_DOCUMENT: 'identity_document',
    INVOICE: 'invoice',
    LEASE_AMENDMENT: 'lease_amendment',
    LEASE_RENEWAL: 'renewal',
    LEASE_TERMINATION: 'lease_termination',
    LEASE: 'lease',
    MISC: 'misc',
    MLS_LISTING_FROM_PURCHASE_DOCUMENT: 'mls_listing_from_purchase_document',
    MOVE_IN_STATEMENT: 'move_in_statement',
    NOTE: 'note',
    NOTE_ATTACHMENT: 'note_attachment',
    OFFER_LETTER: 'offer_letter',
    OTHER_FINANCIAL_DOCUMENT: 'other_financial_document',
    OWNERS_TITLE_POLICY: 'owners_title_policy',
    PAY_STUB: 'pay_stub',
    PENSION: 'pension',
    PHOTO: 'photo',
    PHOTO_ID: 'photo_id',
    PLAID_INCOME_UPLOAD: 'plaid_income_upload',
    PRELIMINARY_TITLE_COMMITMENT: 'preliminary_title_commitment',
    PURCHASE_OPTION_AGREEMENT: 'purchase_option_agreement',
    PURCHASE_OPTION_AGREEMENT_AMENDMENT: 'purchase_option_agreement_amendment',
    PURCHASE_OPTION_AGREEMENT_MEMORANDUM: 'purchase_option_agreement_memorandum',
    PURCHASE_SALE_AGREEMENT_ADDENDUM: 'purchase_sale_agreement_addendum',
    PURCHASE_SALE_AGREEMENT: 'purchase_sale_agreement',
    RENTER_INSURANCE: 'renter_insurance',
    SELLERS_DISCLOSURE_NOTICE: 'sellers_disclosure_notice',
    SIGNED_DEED: 'signed_deed',
    SOCIAL_SECURITY_AWARD_LETTER: 'social_security_award_letter',
    STEP_UP: 'step_up',
    SUPPLEMENTAL_DOCUMENT: 'supplemental_document',
    TAX_RETURN: 'tax_return',
    UTILITY_BILL_UPLOAD: 'utility_bill_upload',
    VENDOR_AGREEMENT: 'vendor_agreement',
    VENDOR_COI: 'vendor_coi',
    VENDOR_PAYMENT_INFORMATION: 'vendor_payment_information',
    VENDOR_W9: 'vendor_w9',
    VOIDED_CHECK: 'voided_check',
    W9: 'w9',
    WARRANTY_INFO: 'warranty_info'
});
exports.FINANCIAL_DOCUMENT_TYPES = Object.freeze([
    exports.DOCUMENT_TYPES.BANK_STATEMENT,
    exports.DOCUMENT_TYPES.OFFER_LETTER,
    exports.DOCUMENT_TYPES.PAY_STUB,
    exports.DOCUMENT_TYPES.TAX_RETURN,
    exports.DOCUMENT_TYPES.PENSION,
    exports.DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER,
    exports.DOCUMENT_TYPES.ARTICLES_OF_ORGANIZATION,
    exports.DOCUMENT_TYPES.OTHER_FINANCIAL_DOCUMENT
]);
exports.USER_INCOME_SOURCE_DOCUMENT_TYPES = Object.freeze([
    exports.DOCUMENT_TYPES.OFFER_LETTER,
    exports.DOCUMENT_TYPES.PAY_STUB,
    exports.DOCUMENT_TYPES.TAX_RETURN,
    exports.DOCUMENT_TYPES.PENSION,
    exports.DOCUMENT_TYPES.SOCIAL_SECURITY_AWARD_LETTER,
    exports.DOCUMENT_TYPES.ARTICLES_OF_ORGANIZATION,
    exports.DOCUMENT_TYPES.OTHER_FINANCIAL_DOCUMENT
]);
