import React, { FunctionComponent, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

type ContentSectionPropsType = {
  title: string
  action?: JSX.Element
  size?: string
  padding?: boolean
  children: React.ReactNode
  staticSize?: boolean
  collapsable?: boolean
  isOpenDefault?: boolean
}

export const ContentSectionCard: FunctionComponent<
  React.PropsWithChildren<ContentSectionPropsType>
> = ({
  title,
  action = null,
  size = 'large',
  padding = false,
  staticSize = false,
  collapsable = true,
  isOpenDefault = true,
  children
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault)

  // style calculations

  const overflow = staticSize ? `` : `overflow-y-auto`

  const paddingDef = padding ? `p-6` : `p-0`

  // if staticSize == true, it should set the static height, not the max-height
  let height = ''
  switch (size) {
    case 'large':
      height = staticSize ? `h-tall` : `max-h-tall`
      break
    case 'fit-content':
      height = 'max-h-fit'
      break
    default:
      height = staticSize ? `h-short` : `max-h-short`
      break
  }

  return (
    <div className='shadow-reg w-full rounded-lg border bg-white'>
      <div
        className={`align-items-center flex justify-between px-6 py-3 text-lg font-medium leading-6 ${
          collapsable && `cursor-pointer`
        }`}
        onClick={() => setIsOpen(collapsable ? !isOpen : true)}
      >
        <div>{title}</div>
        <div className='align-items-center flex justify-between gap-2'>
          <div>{action}</div>
          {collapsable &&
            (isOpen ? (
              <ChevronDownIcon className='clear-left h-6' />
            ) : (
              <ChevronUpIcon className='clear-left h-6' />
            ))}
        </div>
      </div>
      {isOpen ? (
        <>
          {/* bottom border of heading */}
          <div className='h-[1px] w-full bg-slate-200' />

          <div className={`${overflow} ${paddingDef} ${height}`}>{children}</div>
        </>
      ) : null}
    </div>
  )
}
