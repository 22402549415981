import {
  Box,
  Button,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { dates } from '@homevest/utils'
import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community'
import { BaseGrid } from 'components/TailwindUIToolkit'
import {
  CollectionsByMonthQuery,
  LiabilitiesRemainingForCollectionsQuery,
  Note_Priorities_Enum,
  useCancelPromiseToPayMutation,
  useCreatePromiseToPayMutation,
  useUpdatePromiseToPayDateMutation,
  useUpupInsertNoteMutation,
  useUpdateCollectionsZendeskLinkMutation
} from 'graphql/generated'
import { formatMoney } from 'lib/numbers'
import axios from 'lib/axios'
import React, { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import { StoreState } from 'store'
import { Sidepeek } from 'ui'
import { isAfter, format } from 'date-fns'
// import LatePaymentReasonSelect from './LatePaymentReasonSelect'

type Collection = CollectionsByMonthQuery['collections'][number]
type CollectionLiabilitiesRemaining = LiabilitiesRemainingForCollectionsQuery['collections'][number]
export type CollectionRow = {
  collection: Collection
  liabilitiesRemaining?: CollectionLiabilitiesRemaining
}

export const calcProcessingTotal = (payments: Collection['rental']['payments']) =>
  payments.reduce(
    (prev, payment) => prev + (payment.status === 'processing' ? payment.amount : 0),
    0
  )

export const calcRemainingTotal = (
  liabilities: CollectionLiabilitiesRemaining['liabilities_remaining']
) => liabilities.reduce((prev, liability) => prev + liability.amount_remaining, 0)

export const calcOriginalTotal = (
  liabilities: CollectionLiabilitiesRemaining['liabilities_remaining']
) => liabilities.reduce((prev, liability) => prev + liability.original_amount, 0)

const colDefs: ColDef<CollectionRow>[] = [
  {
    colId: 'address',
    headerName: 'rental',
    valueGetter: (d) => d.data?.collection.rental.portfolio_home?.home.address.display_line_1,
    cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
      const rentalData = params.data?.collection.rental
      const address = rentalData?.portfolio_home?.home.address.display_line_1
      const state = rentalData?.portfolio_home?.home.address.state
      const city = rentalData?.portfolio_home?.home.address.city
      const fullAddress = [address, city, state].filter(Boolean).join(', ')
      return (
        <Link isExternal color='#0056b3' as={ReactRouterLink} to={`/rent-roll/${rentalData?.id}`}>
          {fullAddress}
        </Link>
      )
    },
    floatingFilter: true,
    flex: 1.5
  },
  {
    colId: 'in_evictions',
    headerName: 'Eviction?',
    valueGetter: (d) => d.data?.collection.rental.evictions.at(0)?.status,
    valueFormatter: ({ value }) => {
      if (!value) {
        return ''
      }
      return `Yes - ${startCase(value)}`
    },
    floatingFilter: true,
    wrapText: true,
    autoHeight: true,
    flex: 0.8
  },
  {
    colId: 'processing',
    headerName: 'processing',
    flex: 0.9,
    valueGetter: ({ data }) => {
      if (!data || !data.liabilitiesRemaining) {
        return undefined
      }
      const processingTotal = calcProcessingTotal(data.collection.rental.payments)
      const remainingTotal = calcRemainingTotal(data.liabilitiesRemaining.liabilities_remaining)
      return Math.min(processingTotal, remainingTotal)
    },
    cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
      if (params.value === undefined) {
        return <Spinner />
      }
      return formatMoney(params.value, 2, '$')
    }
  },
  {
    colId: 'amount',
    headerName: 'unpaid',
    flex: 0.8,
    valueGetter: ({ data }) => {
      if (!data || !data.liabilitiesRemaining) {
        return undefined
      }
      return calcRemainingTotal(data.liabilitiesRemaining.liabilities_remaining)
    },
    cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
      if (params.value === undefined) {
        return <Spinner />
      }
      const processingTotal = calcProcessingTotal(params.data!.collection.rental.payments)
      const remainingAfterProcessing = Math.max(params.value - processingTotal, 0)
      const originalAmount = calcOriginalTotal(
        params.data!.liabilitiesRemaining!.liabilities_remaining
      )

      if (params.value === 0) {
        return `$0 / ${formatMoney(originalAmount)}`
      }

      return `${formatMoney(remainingAfterProcessing, 2, '$')} ${
        processingTotal > 0
          ? `(+${formatMoney(Math.min(processingTotal, params.value))} processing) `
          : ''
      }/ ${formatMoney(originalAmount, 2)}`
    }
  },
  {
    colId: 'isPaid',
    headerName: 'is paid',
    flex: 0.7,
    valueGetter: ({ data }) => {
      if (!data || !data.liabilitiesRemaining) {
        return undefined
      }

      const isPaid = data.liabilitiesRemaining.liabilities_remaining.every(
        (l) => l.amount_remaining === 0
      )
      const remainingTotal = calcRemainingTotal(data.liabilitiesRemaining.liabilities_remaining)
      const isPaidProcessing =
        calcProcessingTotal(data.collection.rental.payments) >= remainingTotal

      if (isPaid) {
        return 'paid'
      }
      if (isPaidProcessing) {
        return 'paid_processing'
      }
      return 'unpaid'
    },
    cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
      if (params.value === undefined) {
        return <Spinner />
      }
      return { paid: '✅', unpaid: '❌', paid_processing: '⌛' }[
        params.value as 'paid' | 'unpaid' | 'paid_processing'
      ]
    }
  },
  {
    colId: 'most_recent_note',
    headerName: 'Most Recent Note',
    valueGetter: (d) => {
      const note = d.data?.collection.rental.notes.at(0)?.note
      if (!note) {
        return
      }

      const noteDate = new Date(d.data?.collection.rental.notes.at(0)?.created_at)

      return `[${format(noteDate, 'yyyy-MM-dd')}] ${note}`
    },
    cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
      const note = params.data?.collection.rental.notes.at(0)?.note
      if (!note) {
        return ''
      }
      const noteDate = new Date(params.data?.collection.rental.notes.at(0)?.created_at)
      const formattedNoteDate = `[${format(noteDate, 'yyyy-MM-dd')}] `
      return (
        <div>
          <span className='font-semibold'>{formattedNoteDate}</span>
          {note}
        </div>
      )
    },
    floatingFilter: true,
    wrapText: true,
    autoHeight: true,
    flex: 1.5
  },
  // Hidden columns for CSV export
  {
    colId: 'originalBalance',
    headerName: 'original balance',
    hide: true,
    valueGetter: ({ data }) =>
      data?.liabilitiesRemaining?.liabilities_remaining.reduce(
        (acc, l) => acc + l.original_amount,
        0
      )
  },
  {
    colId: 'collectionMonthYear',
    headerName: 'monthYear',
    hide: true,
    valueGetter: (d) => d.data?.collection.month
  },
  {
    colId: 'rentalId',
    headerName: 'rental id',
    hide: true,
    valueGetter: (d) => d.data?.collection.rental.id
  },
  {
    colId: 'collectionId',
    headerName: 'collection id',
    hide: true,
    valueGetter: (d) => d.data?.collection.id
  },
  {
    colId: 'promiseToPayDate',
    headerName: 'promise to pay date',
    hide: true,
    valueGetter: (d) => d.data?.collection.collection_payment_promises?.at(0)?.promise_to_pay_date
  }
]

const Grid = ({
  data = [],
  refetchData,
  onGridReady
}: {
  data?: CollectionRow[]
  refetchData?: () => void
  onGridReady: (evt: GridReadyEvent) => void
}) => {
  const [activeCollectionRow, setActiveCollectionRow] = useState<CollectionRow | null>(null)
  const [isEditCommentsModalOpen, setIsEditCommentsModalOpen] = useState(false)
  const [isZendeskLinkModalOpen, setIsZendeskLinkModalOpen] = useState(false)
  const [isSidepeekOpen, setIsSidepeekOpen] = useState(false)

  const activePromise = activeCollectionRow?.collection.collection_payment_promises.at(0)

  return (
    <>
      <div className='h-[80vh] rounded border'>
        <BaseGrid<CollectionRow>
          rowData={data}
          onGridReady={onGridReady}
          columns={[
            ...colDefs,
            {
              colId: 'comments',
              headerName: 'Comments',
              valueGetter: (d) => d.data?.collection.note?.note,
              cellRenderer: (params: ICellRendererParams<CollectionRow>) => {
                const onClick = () => {
                  setActiveCollectionRow(params.data!)
                  setIsEditCommentsModalOpen(true)
                }

                if (params.value) {
                  return <Text onClick={onClick}>{params.value}</Text>
                }

                return (
                  <Button className='my-2 text-wrap' size='sm' variant='solid' onClick={onClick}>
                    Add Comment
                  </Button>
                )
              },
              floatingFilter: true,
              flex: 1
            },
            {
              colId: 'zendesk_link',
              headerName: 'zendesk_link',
              valueGetter: (d) => d.data?.collection.zendesk_link,
              cellRenderer: (params: ICellRendererParams<CollectionRow>) => (
                <Box
                  onDoubleClick={() => {
                    setActiveCollectionRow(params.data!)
                    setIsZendeskLinkModalOpen(true)
                  }}
                >
                  {params.value ? (
                    <Link className='text-wrap' color='#0056b3' isExternal href={params.value}>
                      {params.value}
                    </Link>
                  ) : (
                    <Text className='italic text-gray-400'>Double-click to add/edit link</Text>
                  )}
                </Box>
              ),
              flex: 0.8
            },
            {
              headerName: 'actions',
              flex: 2,
              sortable: false,
              cellRenderer: ({ data }: ICellRendererParams<CollectionRow>) => {
                if (!data || !data.liabilitiesRemaining) {
                  return null
                }

                const remainingTotal = calcRemainingTotal(
                  data.liabilitiesRemaining.liabilities_remaining
                )

                const isPaid = data.liabilitiesRemaining.liabilities_remaining.every(
                  (l) => l.amount_remaining === 0
                )

                const processingTotal = calcProcessingTotal(data.collection.rental.payments)

                if (isPaid) {
                  return '✅ Paid'
                }

                const isPaidProcessing = processingTotal >= remainingTotal

                if (isPaidProcessing) {
                  return `✅ Paid (${formatMoney(
                    Math.min(processingTotal, remainingTotal),
                    2,
                    '$'
                  )} processing)`
                }

                const activePromise = data.collection.collection_payment_promises.at(0)

                const descriptionText = isAfter(
                  new Date(activePromise?.promise_to_pay_date),
                  new Date()
                )
                  ? 'Promise to pay on'
                  : 'Overdue - promised to pay on'

                if (!!activePromise) {
                  return (
                    <HStack gap='4'>
                      <Button
                        className='my-2 text-wrap'
                        size='sm'
                        onClick={() => {
                          setActiveCollectionRow(data)
                          setIsSidepeekOpen(true)
                        }}
                      >
                        Edit Promise
                      </Button>
                      <Text>
                        {descriptionText}: {activePromise.promise_to_pay_date}
                      </Text>
                    </HStack>
                  )
                }

                return (
                  <Button
                    className='my-2 text-wrap'
                    size='sm'
                    onClick={() => {
                      setActiveCollectionRow(data)
                      setIsSidepeekOpen(true)
                    }}
                  >
                    Promise to Pay
                  </Button>
                )
              }
            }
          ]}
          defaultColDefOverrides={{
            cellStyle: {
              wordBreak: 'normal',
              lineHeight: '20px',
              alignContent: 'center'
            },
            autoHeaderHeight: true,
            wrapHeaderText: true
          }}
          optionOverrides={{ rowHeight: undefined }}
        />
      </div>
      <Sidepeek
        isOpen={isSidepeekOpen}
        onClose={() => {
          setIsSidepeekOpen(false)
          setActiveCollectionRow(null)
        }}
      >
        {!activePromise && (
          <CreatePromiseToPay
            onCreate={() => {
              setIsSidepeekOpen(false)
              setActiveCollectionRow(null)
              refetchData?.()
            }}
            collectionRow={activeCollectionRow!}
          />
        )}
        {!!activePromise && (
          <EditPromiseToPay
            promiseToPay={activePromise}
            onAction={() => {
              setIsSidepeekOpen(false)
              setActiveCollectionRow(null)
              refetchData?.()
            }}
            collectionRow={activeCollectionRow!}
          />
        )}
      </Sidepeek>
      <EditFieldModal
        title='Edit Comments'
        collectionId={activeCollectionRow?.collection.id}
        text={activeCollectionRow?.collection.note?.note}
        isOpen={isEditCommentsModalOpen}
        onClose={() => {
          setIsEditCommentsModalOpen(false)
          setActiveCollectionRow(null)
        }}
        onSubmit={() => refetchData?.()}
        SubmitButton={CommentSubmitButton}
      />
      <EditFieldModal
        title='Zendesk Link'
        collectionId={activeCollectionRow?.collection.id}
        text={activeCollectionRow?.collection.zendesk_link ?? undefined}
        isOpen={isZendeskLinkModalOpen}
        onClose={() => {
          setIsZendeskLinkModalOpen(false)
          setActiveCollectionRow(null)
        }}
        onSubmit={() => refetchData?.()}
        SubmitButton={ZendeskLinkSubmitButton}
      />
    </>
  )
}

const EditPromiseToPay = ({
  promiseToPay,
  collectionRow,
  onAction
}: {
  promiseToPay: Collection['collection_payment_promises'][number]
  collectionRow: CollectionRow
  onAction: () => void
}) => {
  const [editing, setEditing] = useState(false)
  const [promiseToPayDate, setPromiseToPayDate] = useState(promiseToPay.promise_to_pay_date)
  const [customNote, setCustomNote] = useState('')

  const [, cancelPromiseToPay] = useCancelPromiseToPayMutation()
  const [, updatePromiseToPayDate] = useUpdatePromiseToPayDateMutation()
  const [, createNote] = useUpupInsertNoteMutation()

  const adminId = useSelector((store: StoreState) => store.admin.id)

  const cancel = async () => {
    const res = await cancelPromiseToPay({
      promiseId: promiseToPay.id,
      canceledAt: new Date().toISOString()
    })

    const defaultNote = `Canceled promise to pay collection for month of ${collectionRow.collection.month}`

    await createNote({
      note: customNote ? defaultNote + ` [${customNote}]` : defaultNote,
      created_by_admin_id: adminId,
      resource_id: collectionRow.collection.rental.id,
      resource_type: 'rentals',
      priority: Note_Priorities_Enum.Default
    })

    if (res.error) {
      console.error(res.error)
      return
    }

    onAction()
  }

  const update = async () => {
    await updatePromiseToPayDate({ promiseId: promiseToPay.id, promiseToPayDate })

    const defaultNote = `Updated promise to pay collection for month of ${collectionRow.collection.month}`

    await createNote({
      note: customNote ? defaultNote + `[${customNote}]` : defaultNote,
      created_by_admin_id: adminId,
      resource_id: collectionRow.collection.rental.id,
      resource_type: 'rentals',
      priority: Note_Priorities_Enum.Default
    })

    setEditing(false)
    onAction()
  }

  return (
    <Stack>
      <CollectionDetails collectionRow={collectionRow} />
      <Text textDecor='underline'>Existing Promise to Pay</Text>
      <Text>Promise to Pay Date: {promiseToPay.promise_to_pay_date}</Text>
      <Text textDecor='underline'>Custom Note</Text>
      <Textarea value={customNote} onChange={(e) => setCustomNote(e.target.value)} />
      <Button onClick={() => setEditing((prev) => !prev)}>{editing ? 'Back' : 'Edit'}</Button>
      {!editing && <Button onClick={cancel}>Cancel Promise</Button>}
      {editing && (
        <Stack>
          <Input
            type='date'
            value={promiseToPayDate}
            onChange={(e) => setPromiseToPayDate(e.target.value)}
          />
          <Button onClick={update}>Save</Button>
        </Stack>
      )}
    </Stack>
  )
}

const CreatePromiseToPay = ({
  onCreate,
  collectionRow
}: {
  onCreate?: () => void
  collectionRow: CollectionRow
}) => {
  const [promiseToPayDate, setPromiseToPayDate] = useState<string>(dates.formatISODate(new Date()))
  const [doAutocharge, _setDoAutocharge] = useState(false)
  const [customNote, setCustomNote] = useState('')

  const [, createPromiseToPay] = useCreatePromiseToPayMutation()
  const [, createNote] = useUpupInsertNoteMutation()

  const adminId = useSelector((store: StoreState) => store.admin.id)

  const submit = async () => {
    const res = await createPromiseToPay({
      collectionId: collectionRow.collection.id,
      doAutocharge,
      promiseToPayDate,
      adminId
    })

    const defaultNote = `Promised to pay collection for month of ${collectionRow.collection.month} by ${promiseToPayDate}`

    await createNote({
      note: customNote ? defaultNote + ` [${customNote}]` : defaultNote,
      created_by_admin_id: adminId,
      resource_id: collectionRow.collection.rental.id,
      resource_type: 'rentals',
      priority: Note_Priorities_Enum.Default
    })

    if (res.error) {
      console.error(res.error)
      return
    }

    onCreate?.()
  }

  return (
    <Stack>
      <CollectionDetails collectionRow={collectionRow} />
      <Text textDecor='underline'>Create a Promise to Pay</Text>
      <Input
        type='date'
        value={promiseToPayDate}
        onChange={(e) => setPromiseToPayDate(e.target.value)}
      />
      <Text textDecor='underline'>Custom Note</Text>
      <Textarea value={customNote} onChange={(e) => setCustomNote(e.target.value)} />
      <Button onClick={submit}>Create</Button>
    </Stack>
  )
}

const EditFieldModal = ({
  title,
  collectionId,
  text,
  isOpen,
  onClose,
  onSubmit,
  SubmitButton
}: {
  title: string
  collectionId: string
  text?: string
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  SubmitButton: React.FC<{
    collectionId: string
    text?: string
    onSubmit: () => void
  }>
}) => {
  const [textInput, setTextInput] = useState(text)
  useEffect(() => setTextInput(text), [text])

  const onCloseModified = () => {
    onClose()
    setTextInput(undefined)
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModified}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea value={textInput} onChange={(e) => setTextInput(e.target.value ?? '')} />
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            collectionId={collectionId}
            text={textInput}
            onSubmit={() => {
              onCloseModified()
              onSubmit()
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CommentSubmitButton: React.FC<{
  collectionId: string
  text?: string
  onSubmit: () => void
}> = ({ collectionId, text, onSubmit }) => {
  const toast = useToast()
  const adminId = useSelector((store: StoreState) => store.admin.id)

  return (
    <Button
      size='sm'
      onClick={async () => {
        try {
          await axios.post(`/admin/collections/${collectionId}/note`, {
            note: text,
            admin_id: adminId
          })
          onSubmit()
        } catch (err: any) {
          toast({
            title: `Error: ${err.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-right'
          })
        }
      }}
    >
      Submit
    </Button>
  )
}

const ZendeskLinkSubmitButton: React.FC<{
  collectionId: string
  text?: string
  onSubmit: () => void
}> = ({ collectionId, text, onSubmit }) => {
  const toast = useToast()
  const [, updateZendeskLink] = useUpdateCollectionsZendeskLinkMutation()

  return (
    <Button
      size='sm'
      onClick={async () => {
        try {
          updateZendeskLink({ collectionId, zendeskLink: text ?? null })
          onSubmit()
        } catch (err: any) {
          toast({
            title: `Error: ${err.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-right'
          })
        }
      }}
    >
      Submit
    </Button>
  )
}

const CollectionDetails = ({ collectionRow }: { collectionRow: CollectionRow }) => {
  const remainingAmount = collectionRow.liabilitiesRemaining?.liabilities_remaining.reduce(
    (acc, l) => acc + l.amount_remaining,
    0
  )
  const originalAmount = collectionRow.liabilitiesRemaining?.liabilities_remaining.reduce(
    (acc, l) => acc + l.original_amount,
    0
  )

  const rental = collectionRow.collection.rental

  return (
    <Stack>
      <Text textDecor='underline'>Collection Data</Text>
      <Text>
        Rental Address:{' '}
        <a target='_blank' href={process.env.PUBLIC_URL + '/rent-roll/' + rental.id}>
          {rental.portfolio_home?.home.address.display_line_1},{' '}
          {rental.portfolio_home?.home.address.city}, {rental.portfolio_home?.home.address.state}
        </a>
      </Text>
      <Text>
        Remaining Amount: {formatMoney(remainingAmount, 2, '$')} / {formatMoney(originalAmount, 2)}
      </Text>
    </Stack>
  )
}

export default Grid
