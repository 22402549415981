import React, { FunctionComponent } from 'react'
import { ContentSectionCard, LinkButton, SC } from 'components/TailwindUIToolkit'
import { Grid } from '../Grids/ConstructionGrid'
import { Spinner } from 'reactstrap'
import {
  RETOOL_REHABS_TRACKING_APP_ID,
  RETOOL_APP_URL,
  RETOOL_TURNS_TRACKING_APP_ID
} from 'constants/urls'

type WorkOrderHistoryPropTypes = {
  portfolioHomeId: string
}

const ConstructionToolButtons = (
  <SC.ButtonContainer>
    <LinkButton href={`${RETOOL_APP_URL}${RETOOL_REHABS_TRACKING_APP_ID}`}>Rehabs Tool</LinkButton>
    <LinkButton href={`${RETOOL_APP_URL}${RETOOL_TURNS_TRACKING_APP_ID}`}>Turns Tool</LinkButton>
  </SC.ButtonContainer>
)

const WorkOrderHistory: FunctionComponent<React.PropsWithChildren<WorkOrderHistoryPropTypes>> = ({
  portfolioHomeId
}) => (
  <ContentSectionCard
    title='Construction History'
    staticSize
    size='short'
    action={ConstructionToolButtons}
  >
    <React.Suspense fallback={<Spinner />}>
      <Grid portfolioHomeId={portfolioHomeId} />
    </React.Suspense>
  </ContentSectionCard>
)

export default WorkOrderHistory
