import { Input, Tag, TagLabel, TagLeftIcon, TagRightIcon, Tooltip } from '@chakra-ui/react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { PencilIcon } from '@heroicons/react/24/solid'
import { LockboxCodeFragment, useCreateNewLockboxCodeMutation } from 'graphql/generated'
import { first } from 'lodash'
import React, { useState } from 'react'

export const LockboxCode: React.FC<React.PropsWithChildren<LockboxCodeFragment>> = ({
  id: portfolioHomeId,
  active_lockbox_code
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const [{ data }, addLockboxCode] = useCreateNewLockboxCodeMutation()
  const lockboxCode = data?.insert_lockbox_codes_one ?? first(active_lockbox_code)
  const handleAddLockboxCode = async (code: string) => {
    await addLockboxCode({
      code,
      portfolioHomeId
    })
    setIsEditing(false)
  }

  const CodeInput = (
    <>
      <Input
        type='text'
        autoFocus
        size='sm'
        variant='unstyled'
        placeholder={lockboxCode?.code ?? 'Add lockbox code...'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAddLockboxCode(e.currentTarget.value)
          } else if (e.key === 'Escape') {
            setIsEditing(false)
          }
        }}
        onBlur={() => {
          setIsEditing(false)
        }}
      />
    </>
  )

  const createdAt = new Date(lockboxCode?.created_at ?? '')
  const createdBy = `${lockboxCode?.admin?.first_name} ${lockboxCode?.admin?.last_name}`
  const CodeDisplay = (
    <Tooltip
      shouldWrapChildren={!!lockboxCode}
      label={`Updated ${createdAt.toLocaleDateString()} by ${createdBy}`}
    >
      <>
        <TagLeftIcon boxSize='12px' as={LockClosedIcon} />
        <TagLabel>{lockboxCode ? lockboxCode.code : 'Add lockbox code...'}</TagLabel>
        <TagRightIcon
          boxSize='12px'
          as={PencilIcon}
          onClick={() => {
            setIsEditing(true)
          }}
        />
      </>
    </Tooltip>
  )

  if (!portfolioHomeId) {
    return null
  }

  return (
    <Tag onDoubleClick={() => setIsEditing(true)} my={2}>
      {isEditing ? CodeInput : CodeDisplay}
    </Tag>
  )
}
