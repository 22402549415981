import React, { FunctionComponent, useState } from 'react'
import { startCase, maxBy, first } from 'lodash'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  ConstructionProjectsFragment,
  useConstructionByPortfolioHomeIdQuery
} from 'graphql/generated'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import Error from 'components/Toolkit/Text/Error'
import { formatMoney } from 'lib/numbers'
import { formatNullableDateString } from 'lib/date-time'
import { BaseGrid, DisplayDataType, LeftAlignedDataList } from 'components/TailwindUIToolkit'
import { DownloadPdfButtonWithLoaderAnimation, linkButtonToRenowalk } from 'lib/renowalk-pdf'
import { Sidepeek } from 'ui'
import { DocumentsViewAndUploadTool } from 'components/Documents/DocumentsViewAndUploadTool'
import { CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

export type ConstructionProject = ConstructionProjectsFragment

const columns: ColDef<ConstructionProject>[] = [
  {
    headerName: 'Start',
    colId: 'created',
    valueGetter: (params) => first(params.data?.construction_scopes)?.actual_start_date,
    sort: 'desc',
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Completed',
    colId: 'completed',
    valueGetter: (params) => first(params.data?.construction_scopes)?.actual_completion_date,
    valueFormatter: (v) => formatNullableDateString(v.value),
    flex: 2
  },
  {
    headerName: 'Status',
    valueGetter: (v) => v.data?.status,
    cellStyle: { padding: 0 },
    cellRenderer: (params: ICellRendererParams<ConstructionProject, string>) =>
      mapContentToBadge(params.value),
    flex: 2
  },
  {
    headerName: 'Type',
    field: 'type',
    valueFormatter: (v) => startCase(v.value),
    flex: 2
  }
]

interface GridProps {
  portfolioHomeId: string
}

export const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({
  portfolioHomeId
}) => {
  const [{ data, error }] = useConstructionByPortfolioHomeIdQuery({
    variables: { portfolioHomeId }
  })

  const [drawerTarget, setDrawerTarget] = useState<ConstructionProject>()

  if (error) {
    return <Error>{error.message}</Error>
  }

  const acquisitionsConstructionProjects: ConstructionProject[] =
    data?.portfolio_homes_by_pk?.real_estate_acquisition?.construction_projects || []

  const rentalsConstructionProjects: ConstructionProject[] =
    data?.portfolio_homes_by_pk?.rentals.map((r) => r.construction_projects).flat() || []

  const constructionProjects = [...acquisitionsConstructionProjects, ...rentalsConstructionProjects]

  if (!constructionProjects) {
    return <Error>could not find data</Error>
  }

  return (
    <>
      <BaseGrid<ConstructionProject>
        columns={columns}
        rowData={constructionProjects}
        onRowDoubleClicked={(evt) => setDrawerTarget(evt.data)}
      />
      {drawerTarget && (
        <ConstructionProjectDrawer
          cp={drawerTarget}
          isOpen={!!drawerTarget}
          onClose={() => setDrawerTarget(undefined)}
        />
      )}
    </>
  )
}

const ConstructionProjectDrawer: React.FC<
  React.PropsWithChildren<{
    cp: ConstructionProject
    isOpen: boolean
    onClose: () => void
  }>
> = ({ cp, isOpen, onClose }) => {
  const renowalkId = first(cp.construction_scopes)?.renowalk_id

  const getEstimatedCost = () => {
    // return most recent estimate if there are multiple
    const estimates = first(cp.construction_scopes)?.construction_estimates
    return maxBy(estimates, (e) => e.created_at)?.estimated_cost
  }

  const displayInfo: DisplayDataType = [
    {
      label: 'vendor',
      value: first(first(cp.construction_scopes)?.construction_scopes_vendors)?.vendor?.name
    },
    {
      label: 'start',
      value: formatNullableDateString(first(cp.construction_scopes)?.actual_start_date)
    },
    {
      label: 'completed',
      value: formatNullableDateString(first(cp.construction_scopes)?.actual_completion_date)
    },
    {
      label: 'status',
      value: mapContentToBadge(cp.status)
    },
    {
      label: 'type',
      value: cp.type
    },
    {
      label: 'field super',
      value: cp.admin && `${cp.admin?.first_name} ${cp.admin?.last_name}`
    },
    {
      label: 'estimated cost',
      value: formatMoney(getEstimatedCost(), 2, '$')
    },
    {
      label: 'scoped cost',
      value: formatMoney(first(cp.construction_scopes)?.actual_cost, 2, '$')
    },
    {
      label: 'link to renowalk',
      value: linkButtonToRenowalk(renowalkId)
    },
    {
      label: 'renowalk pdfs',
      value: DownloadPdfButtonWithLoaderAnimation(renowalkId)
    }
  ]

  return (
    <Sidepeek title='Construction Project Details' isOpen={isOpen} onClose={onClose}>
      <LeftAlignedDataList data={displayInfo} />
      <DocumentsViewAndUploadTool
        resourceId={cp.id}
        resourceType='construction_projects'
        resourceTypeMap={CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP}
      />
    </Sidepeek>
  )
}
