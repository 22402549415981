import { round } from 'lodash'

export function formatMoney(number?: number | null, decimals = 2, currency = ''): string {
  if (typeof number !== 'number') {
    return ''
  } else if (number === 0) {
    return currency + '0'
  } else {
    return `${currency}${Number(number).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })}`
  }
}

export const formatPercent = (number?: number | null, decimals = 2): string => {
  if (typeof number !== 'number') {
    return ''
  } else {
    return Number(number).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })
  }
}

export const formatMonths = (numberOfMonths?: number | null, defaultString = '-'): string => {
  if (typeof numberOfMonths !== 'number') {
    return defaultString
  }

  return `${numberOfMonths} mo.`
}

export const roundToNearest = (
  number: number,
  nearestMultiple: number,
  precision: number = 0
): number => {
  return round(number / nearestMultiple, precision) * nearestMultiple
}
