import React, { FunctionComponent } from 'react'

export const TwoColumn: FunctionComponent<
  React.PropsWithChildren<{
    mainContent: JSX.Element
    sidebarContent: JSX.Element
    sidebarSide?: 'left' | 'right'
    sidebarSize?: 'sm' | 'md' | 'lg'
    useRowsOnSmallScreens?: boolean
  }>
> = ({
  mainContent,
  sidebarContent,
  sidebarSide = 'right',
  sidebarSize = 'md',
  useRowsOnSmallScreens = false
}) => {
  const sidebarWidth = {
    sm: 'w-1/5',
    md: 'w-1/3',
    lg: 'w-1/2'
  }[sidebarSize]
  const mainWidth = {
    sm: 'w-4/5',
    md: 'w-2/3',
    lg: 'w-1/2'
  }[sidebarSize]

  const sidebar = (
    <aside
      className={`relative z-0 flex-shrink-0 flex-col gap-6 ${
        useRowsOnSmallScreens ? `flex w-full md:${sidebarWidth}` : `hidden md:flex ${sidebarWidth}`
      }`}
    >
      {/* Start secondary column (hidden on smaller screens) */}
      {sidebarContent}
      {/* End secondary column */}
    </aside>
  )

  return (
    <div
      className={`relative z-0 flex flex-shrink flex-grow gap-6 overflow-visible ${
        useRowsOnSmallScreens ? 'flex-col md:flex-row' : ''
      }`}
    >
      {sidebarSide === 'left' ? sidebar : null}
      <main
        className={`relative z-0 mb-6 flex h-full flex-shrink flex-grow flex-col gap-6 ${
          useRowsOnSmallScreens ? `w-full md:${mainWidth}` : mainWidth
        }`}
      >
        {/* Start main area*/}
        {mainContent}
        {/* End main area */}
      </main>
      {sidebarSide === 'right' ? sidebar : null}
    </div>
  )
}
