import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import React, { FunctionComponent } from 'react'

type Option = {
  label: string
  checked: boolean
  value: string | null | undefined
}

type OptionClickedFunction = (value: string | null | undefined, checked: boolean) => void

interface CheckboxListProps {
  options: Option[]
  onOptionClicked: OptionClickedFunction
}

export const CheckboxList: FunctionComponent<React.PropsWithChildren<CheckboxListProps>> = ({
  options,
  onOptionClicked
}) => {
  return (
    <fieldset className='space-y-5 rounded-md p-4'>
      {options.map((option) => {
        return <CheckboxOption option={option} onOptionClicked={onOptionClicked} />
      })}
    </fieldset>
  )
}

const CheckboxOption: FunctionComponent<
  React.PropsWithChildren<{
    onOptionClicked: OptionClickedFunction
    option: Option
  }>
> = ({ option, onOptionClicked }) => {
  return (
    <div className='relative flex items-start'>
      <div className='flex h-5 items-center'>
        <input
          id={option.label}
          name={option.label}
          onChange={(evt) => {
            onOptionClicked(option.value, evt.target.checked)
          }}
          type='checkbox'
          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
        />
      </div>
      <div className='ml-3 text-sm'>
        <label htmlFor={option.label} className='font-medium text-gray-700'>
          {mapContentToBadge(option.label)}
        </label>
      </div>
    </div>
  )
}
