import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import { Center, Link, MenuList, MenuItem } from '@chakra-ui/react'
import { capabilities } from '@homevest/utils'

import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'

import TabMenu from './TabMenu'
import TabLink from './TabLinkWrapper'

const {
  APPLICATION_VIEWER,
  DAGGER,
  DELINQUENCY_MANAGER,
  MLS_UPLOADER,
  PERMISSION_EDITOR,
  LEDGER_VIEWER
} = capabilities.CAPABILITY_TYPES

const TabNavigation: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation()

  const admin = useSelector<StoreState, any>((store) => store.admin)
  const isDagger = hasCapability(admin, DAGGER)
  const isDelinquencyManager = hasCapability(admin, DELINQUENCY_MANAGER)
  const isRentalAppViewer = hasCapability(admin, APPLICATION_VIEWER)
  const isPermissionEditor = hasCapability(admin, PERMISSION_EDITOR)
  const isLedgerViewer = hasCapability(admin, LEDGER_VIEWER)
  const isMlsUploader = hasCapability(admin, MLS_UPLOADER)

  return (
    <>
      <TabMenu label='Properties' active={pathname.match('/homes|/properties|/rent-roll') !== null}>
        <MenuList>
          {isDelinquencyManager && (
            <MenuItem as={ReactLink} to='/delinquency-manager' fontSize='sm'>
              Delinquency Management
            </MenuItem>
          )}
          <MenuItem as={ReactLink} to='/homes' fontSize='sm'>
            Homes
          </MenuItem>
          {isLedgerViewer && (
            <MenuItem as={ReactLink} to='/rent-roll' fontSize='sm'>
              Rent Roll
            </MenuItem>
          )}
          <MenuItem as={ReactLink} to='/properties/renewal-manager' fontSize='sm'>
            Renewal Manager
          </MenuItem>
          <MenuItem as={ReactLink} to='/utilities/chargebacks-manager' fontSize='sm'>
            Utilities
          </MenuItem>
          <MenuItem as={ReactLink} to='/collections' fontSize='sm'>
            Collections
          </MenuItem>
          <MenuItem as={ReactLink} to='/properties' fontSize='sm'>
            Search Listings
          </MenuItem>
          <MenuItem
            as={Link}
            href='https://upandup.tryretool.com/apps/ac67b3c0-d541-11eb-bf5d-532c250792e4/Data%20Entry/Rent%20Price%20Manager'
            target='_blank'
            fontSize='sm'
          >
            Rent Price Manager
          </MenuItem>
          {isMlsUploader && (
            <MenuItem as={ReactLink} to='/properties/mls-uploader' fontSize='sm'>
              MLS Uploader
            </MenuItem>
          )}
          <MenuItem as={ReactLink} to='/properties/rental-site-checker' fontSize='sm'>
            Rental Site Checker
          </MenuItem>
        </MenuList>
      </TabMenu>
      <TabMenu label='Customers' active={pathname.match('/users') !== null}>
        <MenuList>
          <MenuItem as={ReactLink} to='/users' fontSize='sm'>
            Search
          </MenuItem>
          <MenuItem as={ReactLink} to='/users/create' fontSize='sm'>
            Create
          </MenuItem>
        </MenuList>
      </TabMenu>

      <TabMenu
        label='Applications'
        active={pathname.match('/applications|/review|/dispatch-lead-groups') !== null}
      >
        <MenuList>
          {isRentalAppViewer && (
            <MenuItem as={ReactLink} to='/applications' fontSize='sm'>
              View All
            </MenuItem>
          )}
          <MenuItem as={ReactLink} to='/dispatch-lead-groups' fontSize='sm'>
            Lead Groups
          </MenuItem>
          <MenuItem as={ReactLink} to='/review' fontSize='sm'>
            Pending Move-In Payments
          </MenuItem>
        </MenuList>
      </TabMenu>

      <TabMenu label='Construction' active={pathname.match('/construction') !== null}>
        <MenuList>
          <MenuItem as={ReactLink} to='/vendors' fontSize='sm'>
            Vendors
          </MenuItem>
          <MenuItem as={ReactLink} to='/construction/latchel-sync-manager' fontSize='sm'>
            Latchel Sync Manager
          </MenuItem>
        </MenuList>
      </TabMenu>

      {isDagger && (
        <TabMenu label='Financial' active={pathname.match('/statements') !== null}>
          <MenuList>
            <MenuItem as={ReactLink} to='/statements/bulk-je-uploader' fontSize='sm'>
              Bulk Journal Entry Uploader
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/gl-transactions' fontSize='sm'>
              GL Transactions
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/hpa-engine' fontSize='sm'>
              HPA Engine
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/fmv' fontSize='sm'>
              FMV Marks
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/mortgage-transformer' fontSize='sm'>
              Mortgage Transformer
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/proration-engine' fontSize='sm'>
              Proration Engine
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/send-test' fontSize='sm'>
              Send Test Statements
            </MenuItem>
            <MenuItem as={ReactLink} to='/statements/backup-and-rebuild' fontSize='sm'>
              Snapshot & Rebuild Statements
            </MenuItem>
          </MenuList>
        </TabMenu>
      )}

      <TabLink active={pathname.match('/documents') !== null}>
        <Link as={ReactLink} to='/documents' h='full' px={2}>
          <Center h='full'>Documents</Center>
        </Link>
      </TabLink>

      {isPermissionEditor && (
        <TabLink active={pathname.match('/capabilities') !== null}>
          <Link as={ReactLink} to='/capabilities' h='full' px={2}>
            <Center h='full'>Permissions</Center>
          </Link>
        </TabLink>
      )}
    </>
  )
}

export default TabNavigation
