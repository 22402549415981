import React from 'react'
import startCase from 'lodash/startCase'
import { Link as ReactRouterLink } from 'react-router-dom'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { HStack, Link, Text } from '@chakra-ui/react'

import { TicketsByActiveVendorQuery } from 'graphql/generated'
import { StarRatingDisplay } from 'components/TailwindUIToolkit'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { formatPhoneFromDatabase } from 'lib/formatting'

export type TicketRow = TicketsByActiveVendorQuery['tickets'][number]

const TITLE: ColDef<TicketRow> = {
  colId: 'title',
  headerName: 'Title',
  field: 'title',
  tooltipField: 'title',
  flex: 2,
  resizable: true,
  cellStyle: { display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
  cellRenderer: (params: ICellRendererParams<TicketRow>) => {
    if (params.data?.external_source !== 'latchel' || !params.data?.tickets_latchel_data?.slug) {
      return params.value
    }

    return (
      <Link
        href={`https://app.latchel.com/admin/work-order/${params.data.tickets_latchel_data.slug}`}
        isExternal
        target={'_blank'}
        rel={'noreferrer noopener'}
      >
        <HStack>
          <Text>{params.value}</Text>
          <ArrowTopRightOnSquareIcon className='h-4 w-4' />
        </HStack>
      </Link>
    )
  }
}

const HOME: ColDef<TicketRow> = {
  colId: 'home',
  headerName: 'Home',
  resizable: true,
  flex: 1,
  valueGetter: (params) => params.data?.portfolio_home?.home?.address?.display_line_1 || '',
  cellRenderer: (params: ICellRendererParams<TicketRow>) => (
    <Link
      as={ReactRouterLink}
      to={`/home/${params.data?.portfolio_home?.id}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Text>{params.value}</Text>
    </Link>
  )
}

const ADDRESS: ColDef<TicketRow> = {
  colId: 'address',
  headerName: 'Address',
  field: 'portfolio_home.address.display_line_1',
  hide: true,
  valueGetter: (params) => {
    const address = params.data?.portfolio_home?.home?.address

    if (!address) {
      return ''
    }

    return `${address.display_line_1 ?? ''}, ${address.display_line_2 ?? ''}`
  }
}

const TENANT_NAME: ColDef<TicketRow> = {
  colId: 'tenant_name',
  headerName: 'Tenant Name',
  hide: true,
  valueGetter: (params) => {
    const user = params.data?.portfolio_home?.rentals?.[0]?.rental_users?.[0]?.user

    if (!user) {
      return ''
    }

    return `${user.first_name} ${user.last_name}`
  }
}

const TENANT_PHONE: ColDef<TicketRow> = {
  colId: 'tenant_phone',
  headerName: 'Tenant Phone',
  hide: true,
  valueGetter: (params) =>
    formatPhoneFromDatabase(
      params.data?.portfolio_home?.rentals?.[0]?.rental_users?.[0]?.user?.user_contact_details?.[0]
        ?.contact_info
    )
}

const LATCHEL_LINK: ColDef<TicketRow> = {
  colId: 'latchel_link',
  headerName: 'Latchel Link',
  hide: true,
  valueGetter: (params) => {
    if (params.data?.external_source !== 'latchel' || !params.data?.tickets_latchel_data?.slug) {
      return null
    }

    return `https://app.latchel.com/admin/work-order/${params.data.tickets_latchel_data.slug}`
  },
  flex: 1
}

const TYPE: ColDef<TicketRow> = {
  colId: 'type',
  headerName: 'Type',
  field: 'type',
  resizable: true,
  valueFormatter: (params) => startCase(params.value),
  flex: 1
}

const DESCRIPTION: ColDef<TicketRow> = {
  colId: 'description',
  headerName: 'Description',
  field: 'description',
  tooltipField: 'description',
  flex: 2,
  resizable: true,
  cellStyle: { display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
}

const STATUS: ColDef<TicketRow> = {
  colId: 'status',
  headerName: 'Status',
  field: 'status',
  resizable: true,
  valueFormatter: (params) => startCase(params.value),
  flex: 1
}

const START_DATE: ColDef<TicketRow> = {
  colId: 'start_date',
  headerName: 'Start Date',
  filter: 'agDateColumnFilter',
  resizable: true,
  sort: 'asc',
  valueGetter: (params) => {
    if (params.data?.actual_start_date) {
      return params.data.actual_start_date
    } else if (params.data?.current_start_date) {
      return params.data.current_start_date
    }
    return params.data?.planned_start_date
  },
  valueFormatter: (params) => {
    if (params.data?.actual_start_date) {
      return `${params.data.actual_start_date} (Act)`
    } else if (params.data?.current_start_date) {
      return `${params.data.current_start_date} (Cur)`
    } else if (params.data?.planned_start_date) {
      return `${params.data?.planned_start_date} (Plan)`
    }

    return ''
  },
  flex: 1
}

const END_DATE: ColDef<TicketRow> = {
  colId: 'end_date',
  headerName: 'End Date',
  filter: 'agDateColumnFilter',
  resizable: true,
  valueGetter: (params) => params.data?.actual_end_date || params.data?.estimated_end_date,
  valueFormatter: (params) => {
    if (params.data?.actual_end_date) {
      return `${params.data.actual_end_date} (Act)`
    } else if (params.data?.estimated_end_date) {
      return `${params.data.estimated_end_date} (Est)`
    } else {
      return ''
    }
  },
  flex: 1
}

const RATING: ColDef<TicketRow> = {
  colId: 'internal_vendor_rating',
  headerName: 'Rating',
  filter: 'agNumberColumnFilter',
  resizable: true,
  valueGetter: (params) => params.data?.ticket_vendor_assignments?.[0]?.internal_vendor_rating || 0,
  cellRenderer: (params: ICellRendererParams<TicketRow>) => (
    <StarRatingDisplay rating={params.value || 0} />
  ),
  flex: 1
}

export const COLUMNS_FOR_CALENDAR_EXPORT = [
  TITLE,
  ADDRESS,
  STATUS,
  START_DATE,
  END_DATE,
  TENANT_NAME,
  TENANT_PHONE,
  LATCHEL_LINK
]

export const ALL_COLUMNS = [
  TITLE,
  HOME,
  TYPE,
  DESCRIPTION,
  STATUS,
  START_DATE,
  END_DATE,
  RATING,
  // Hidden Columns for export
  ADDRESS,
  TENANT_NAME,
  TENANT_PHONE,
  LATCHEL_LINK
]
