import { first } from 'lodash'
import { ICellRendererParams, ColDef } from 'ag-grid-community'
import { rentals } from '@homevest/utils'
import SetFilter from './filters/SetFilter'
import { formatMoney } from 'lib/numbers'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { PropertyGridDataType } from './HomesGrid'

export enum Homes360View {
  OVERVIEW = 'overview',
  CONSTRUCTION = 'construction',
  ASSET_MANAGEMENT = 'asset_mgmt',
  PROPERTY_MANAGEMENT = 'property_mgmt'
}

const ID: ColDef<PropertyGridDataType> = {
  headerName: 'ID',
  field: 'id',
  hide: true
}

const ADDRESS: ColDef<PropertyGridDataType> = {
  colId: 'address',
  headerName: 'Address',
  field: 'display_line_1',
  getQuickFilterText: (params) => params.value,
  onCellClicked: (params) => {
    params.context.navigateToPage(`/home/${params.data?.id}`)
  }
}

const TENANT: ColDef<PropertyGridDataType> = {
  colId: 'tenant',
  headerName: 'Tenant',
  valueGetter: (params) =>
    params.data?.tenant_first_name
      ? `${params.data.tenant_first_name} ${params.data.tenant_last_name}`
      : null,
  valueFormatter: ({ value }) => value ?? 'N/A',
  getQuickFilterText: (params) => params.value,
  onCellClicked: (params) => {
    if (params.data?.most_recent_rental_id) {
      params.context.navigateToPage(`/rent-roll/${params.data?.most_recent_rental_id}`)
    }
  },
  comparator: (a, b, _nodeA, _nodeB, isDescending) => {
    if (a === b) {
      return 0
    } else if (a === null) {
      return isDescending ? -1 : 1
    } else if (b === null) {
      return isDescending ? 1 : -1
    } else {
      return a.localeCompare(b)
    }
  }
}

const FUND: ColDef<PropertyGridDataType> = {
  headerName: 'Fund',
  field: 'fund_name',
  filter: SetFilter,
  width: 150
}

const ENTITY: ColDef<PropertyGridDataType> = {
  headerName: 'Entity',
  field: 'entity_name'
}

const OCCUPANCY_STATUS: ColDef<PropertyGridDataType> = {
  headerName: 'Occupancy Status',
  field: 'occupancy_status',
  cellRenderer: (params: ICellRendererParams<PropertyGridDataType, string>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const CONSTRUCTION_STATUS: ColDef<PropertyGridDataType> = {
  headerName: 'Construction Status',
  field: 'construction_status',
  cellRenderer: (params: ICellRendererParams<PropertyGridDataType, string>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const OWNERSHIP_STATUS: ColDef<PropertyGridDataType> = {
  headerName: 'Ownership Status',
  field: 'ownership_status',
  cellRenderer: (params: ICellRendererParams<PropertyGridDataType, string>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const SUMMARY_STATUS: ColDef<PropertyGridDataType> = {
  headerName: 'Summary Status',
  field: 'summary_status',
  valueGetter: (params) => {
    const incident = first(params.data?.portfolio_home?.down_status_incidents)

    return incident ? 'down' : params.data?.summary_status
  },
  cellRenderer: (params: ICellRendererParams<PropertyGridDataType, string>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter,
  width: 220
}

const RENTAL_PROGRAM_TYPE: ColDef<PropertyGridDataType> = {
  headerName: 'Program Type',
  valueGetter: (params) =>
    params.data?.portfolio_home
      ? rentals.RENTAL_PROGRAM_LEASE_TYPES[params.data.portfolio_home.rental_program_type]
      : undefined,
  cellRenderer: (params: ICellRendererParams<PropertyGridDataType, string>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const MARKET: ColDef<PropertyGridDataType> = {
  headerName: 'Market',
  field: 'market.display_name',
  filter: SetFilter
}

const BEDS: ColDef<PropertyGridDataType> = {
  colId: 'num_beds',
  headerName: 'Beds',
  width: 100,
  field: 'beds',
  filter: 'number',
  filterParams: {
    defaultOption: 'greaterThanOrEqual'
  }
}

const BATHS: ColDef<PropertyGridDataType> = {
  colId: 'num_baths',
  headerName: 'Baths',
  width: 120,
  field: 'baths_total',
  filter: 'number',
  filterParams: {
    defaultOption: 'greaterThanOrEqual'
  }
}

const CLOSING_DATE: ColDef<PropertyGridDataType> = {
  headerName: 'Closing Date',
  field: 'portfolio_home.real_estate_acquisition.closing_date',
  filter: 'date'
}

const PURCHASE_PRICE: ColDef = {
  headerName: 'Purchase Price',
  field: 'portfolio_home.real_estate_acquisition.final_purchase_price',
  valueFormatter: (v) => formatMoney(v.value, 0, '$'),
  filter: 'number'
}

const RENT_READY_DATE: ColDef<PropertyGridDataType> = {
  headerName: 'Rent Ready',
  colId: 'rent_ready_date',
  valueGetter: (params) => {
    const rentReadyDate = first(
      params.data?.portfolio_home?.real_estate_acquisition?.opportunity?.rent_ready_dates
    )
    return rentReadyDate?.earliest_move_in_date
  },
  filter: 'date'
}

const CITY: ColDef<PropertyGridDataType> = {
  headerName: 'City',
  field: 'city'
}

const ZIPCODE: ColDef<PropertyGridDataType> = {
  headerName: 'Zip',
  field: 'zip'
}

const SQFT: ColDef = {
  headerName: 'SqFt',
  field: 'sqft'
}

const YEAR_BUILT: ColDef = {
  headerName: 'Built',
  field: 'year_built'
}

const LOCKBOX_CODE: ColDef<PropertyGridDataType> = {
  field: 'portfolio_home.active_lockbox_code.0.code',
  headerName: 'Lockbox Code',
  filter: 'text'
}

const MOVE_OUT_DATE: ColDef<PropertyGridDataType> = {
  headerName: 'Move Out Date',
  field: 'most_recent_rental_move_out_date',
  filter: 'date'
}

const DEFAULT_VIEW: ColDef[] = [
  ID,
  ADDRESS,
  CITY,
  ZIPCODE,
  OCCUPANCY_STATUS,
  TENANT,
  OWNERSHIP_STATUS,
  CONSTRUCTION_STATUS,
  SUMMARY_STATUS,
  RENTAL_PROGRAM_TYPE,
  MARKET,
  BEDS,
  BATHS,
  SQFT,
  YEAR_BUILT,
  CLOSING_DATE,
  PURCHASE_PRICE,
  RENT_READY_DATE,
  FUND,
  ENTITY,
  LOCKBOX_CODE
]

const CONSTRUCTION_VIEW: ColDef[] = [
  ID,
  ADDRESS,
  CITY,
  MARKET,
  ZIPCODE,
  SUMMARY_STATUS,
  CONSTRUCTION_STATUS,
  OCCUPANCY_STATUS,
  OWNERSHIP_STATUS,
  SQFT,
  YEAR_BUILT,
  BEDS,
  BATHS,
  RENT_READY_DATE,
  LOCKBOX_CODE
]

// TODO: add rental balance? maybe this becomes rent-roll starting point once we add a new balance caching table
const PROPERTY_MANAGEMENT_VIEW: ColDef[] = [
  TENANT,
  ADDRESS,
  CITY,
  ZIPCODE,
  MARKET,
  OCCUPANCY_STATUS,
  OWNERSHIP_STATUS,
  CONSTRUCTION_STATUS,
  SUMMARY_STATUS,
  RENTAL_PROGRAM_TYPE,
  MOVE_OUT_DATE,
  SQFT,
  YEAR_BUILT,
  BEDS,
  BATHS,
  RENT_READY_DATE,
  LOCKBOX_CODE
]

const ASSET_MANAGEMENT_VIEW: ColDef[] = [
  ADDRESS,
  MARKET,
  SUMMARY_STATUS,
  OCCUPANCY_STATUS,
  OWNERSHIP_STATUS,
  CONSTRUCTION_STATUS,
  ENTITY,
  FUND,
  CLOSING_DATE
]

export const getColumnsForView = (view: Homes360View): ColDef<PropertyGridDataType>[] => {
  switch (view) {
    case Homes360View.CONSTRUCTION:
      return CONSTRUCTION_VIEW
    case Homes360View.PROPERTY_MANAGEMENT:
      return PROPERTY_MANAGEMENT_VIEW
    case Homes360View.ASSET_MANAGEMENT:
      return ASSET_MANAGEMENT_VIEW
    case Homes360View.OVERVIEW:
      return DEFAULT_VIEW
  }
}
