import React from 'react'
import { Helmet } from 'react-helmet'
import { Heading } from '@chakra-ui/react'
import VendorsGrid from 'components/Vendors/Grid/VendorsGrid'

const Vendors = () => {
  return (
    <>
      <Helmet>
        <title>Vendors</title>
      </Helmet>
      <Heading pt='4' px='4'>
        Vendors
      </Heading>
      <VendorsGrid />
    </>
  )
}

export default Vendors
