import React, { FunctionComponent, useState } from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { IconButton } from '@chakra-ui/react'
import axios from 'lib/axios'
import {
  FindDocumentsByResourceIdsQuery,
  useFindDocumentsByResourceIdsQuery
} from 'graphql/generated'
import Error from 'components/Toolkit/Text/Error'
import { Sidepeek } from 'ui'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { DocumentDisplayData } from 'components/Documents/DocumentDisplayData'

export type Document = FindDocumentsByResourceIdsQuery['documents'][number]

interface GridProps {
  resourceIds: string[]
}

const onView = async (document: Document) => {
  const { data } = await axios.get(`/admin/documents/${document.id}/url`)
  window.open(data.url, '_blank')
}

const columns: ColDef<Document>[] = [
  {
    headerName: 'Document',
    field: 'friendly_name',
    flex: 3,
    cellStyle: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      alignItems: 'start',
      paddingTop: '16px',
      paddingBottom: '16px'
    }
  },
  {
    headerName: 'doc type',
    field: 'type',
    valueFormatter: (v) => startCase(v.value),
    flex: 3
  },
  {
    headerName: 'attached to',
    field: 'resource_type',
    valueFormatter: (v) => startCase(v.value),
    flex: 3
  },
  {
    headerName: 'date',
    field: 'created_at',
    sort: 'desc',
    valueFormatter: (v) => format(new Date(v.value), 'PP'),
    flex: 3
  },
  {
    headerName: '',
    cellRenderer: (v: ICellRendererParams<Document>) => (
      <IconButton
        p={2}
        icon={<ArrowDownTrayIcon />}
        aria-label='download'
        onClick={() => v.data && onView(v.data)}
      />
    ),
    flex: 2
  }
]

const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({ resourceIds }) => {
  const [{ data, error }] = useFindDocumentsByResourceIdsQuery({
    variables: { resourceIds }
  })

  const [drawerTarget, setDrawerTarget] = useState<Document>()

  if (error) {
    return <Error>{error.message}</Error>
  }

  const documents = data?.documents

  if (!documents) {
    return <Error>documents not found</Error>
  }

  return (
    <>
      <BaseGrid<Document>
        columns={columns}
        rowData={documents}
        onRowDoubleClicked={(evt) => {
          setDrawerTarget(evt.data)
        }}
      />
      {drawerTarget && (
        <DocumentDrawer
          doc={drawerTarget}
          isOpen={!!drawerTarget}
          onClose={() => setDrawerTarget(undefined)}
        />
      )}
    </>
  )
}

const DocumentDrawer: React.FC<
  React.PropsWithChildren<{
    doc: Document
    isOpen: boolean
    onClose: () => void
  }>
> = ({ doc, isOpen, onClose }) => {
  return (
    <Sidepeek title='Document Details' isOpen={isOpen} onClose={onClose}>
      <DocumentDisplayData doc={doc} />
    </Sidepeek>
  )
}

export default Grid
