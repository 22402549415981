import React, { FunctionComponent, Suspense, useMemo } from 'react'
import { format, parseISO, getDaysInMonth, getDate } from 'date-fns'
import { Flex, Text, Spinner, Center, Stack, StackDivider } from '@chakra-ui/react'
import { startCase, sortBy } from 'lodash'
import { Link } from 'ui'
import { useUpupRentalApplicationByIdQuery } from 'graphql/generated'
import { combineFirstNameLastName } from 'lib/utils'
import { formatMoney } from 'lib/numbers'

const SidePeekDetails: FunctionComponent<React.PropsWithChildren<{ appId: string }>> = ({
  appId
}) => (
  <Suspense
    fallback={
      <Center h={32}>
        <Spinner />
      </Center>
    }
  >
    <SidePeekDetailsContent appId={appId} />
  </Suspense>
)

const SidePeekDetailsContent: FunctionComponent<React.PropsWithChildren<{ appId: string }>> = ({
  appId
}) => {
  const [{ data, error }] = useUpupRentalApplicationByIdQuery({
    variables: { id: appId },
    pause: !appId
  })

  const moveInCost = useMemo(() => {
    const moveInDate = data?.rental_applications_by_pk?.projected_occupancy_date
    const rent = data?.rental_applications_by_pk?.rent
    return appId && moveInDate && rent ? getProjectedMoveInCost(rent, moveInDate) : null
  }, [appId, data])

  if (error) {
    return <div>Please let Eng know something went wrong 😢</div>
  }
  const app = data?.rental_applications_by_pk
  const primary = data?.rental_applications_by_pk?.lead_group.lead_group_users.find(
    (lgu) => lgu.type === 'primary'
  )

  const assignments = app?.lead_group.agent_lead_group_owners ?? []
  const rentReadyDate = app?.mls_listing.rent_ready_dates[0]?.earliest_move_in_date

  return (
    <Stack direction='column' divider={<StackDivider />}>
      <Stack direction='column' spacing={3} align='start'>
        <Text fontWeight={600}>Applicant(s)</Text>
        <Stack
          direction='column'
          spacing={2}
          align='stretch'
          w='full'
          fontSize='sm'
          textAlign='left'
          whiteSpace='nowrap'
        >
          {app?.lead_group.lead_group_users.map((lgu) => (
            <Flex direction='row' align='center' key={lgu.id} gap={4}>
              <Text flex={1} fontWeight={500} color='gray.400'>
                {startCase(lgu.type)}
              </Text>
              <Link flex={2} to={`/users/${lgu.user.id}`} useRouter isExternal>
                {combineFirstNameLastName(lgu.user)}
              </Link>
            </Flex>
          ))}
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Link to Close
            </Text>
            <Link
              flex={2}
              href={`https://app.close.com/lead/${primary?.user.close_lead_id}`}
              isExternal
            >
              Close
            </Link>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Application created
            </Text>
            <Text flex={2}>
              {app?.created_at
                ? format(parseISO(app?.created_at), "MMM dd, yyyy 'at' HH:mm")
                : null}
            </Text>
          </Flex>
        </Stack>
      </Stack>
      <Stack direction='column' spacing={3} align='start'>
        <Text fontWeight={600}>Property</Text>
        <Stack
          direction='column'
          spacing={2}
          align='stretch'
          w='full'
          fontSize='sm'
          textAlign='left'
          whiteSpace='nowrap'
        >
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Address
            </Text>
            <Link flex={2} to={`/applications/${app?.id}`} isExternal useRouter>
              {app?.mls_listing.display_line_1 ?? null}
            </Link>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Rent ready date
            </Text>
            <Text flex={2}>
              {rentReadyDate ? format(parseISO(rentReadyDate), 'MMM dd, yyyy') : null}
            </Text>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Home rematching
            </Text>
            <Link
              flex={2}
              href={`https://upandup.tryretool.com/apps/6899f83e-dc3d-11ec-b888-b74d10445f94/Leasing/Home%20Matching%20Tool?_releaseVersion=latest#${app?.mls_listing.id}&mls_listing_id=${app?.mls_listing.id}`}
              isExternal
            >
              Home Rematching Tool
            </Link>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Property rent
            </Text>
            <Text flex={2}>{app?.rent ? formatMoney(app.rent, 0, '$') : 'N/A'}</Text>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Option premium
            </Text>
            <Text flex={2}>{formatMoney(50, 0, '$')}</Text>
          </Flex>
          <Flex direction='row' align='center' gap={4}>
            <Text flex={1} fontWeight={500} color='gray.400'>
              Projected move-in cost
            </Text>
            <Text flex={2}>{moveInCost ? formatMoney(moveInCost, 0, '$') : 'N/A'}</Text>
          </Flex>
        </Stack>
      </Stack>
      <Stack direction='column' spacing={3} align='start'>
        <Text fontWeight={600}>Team members</Text>
        <Stack
          direction='column'
          spacing={2}
          align='stretch'
          w='full'
          fontSize='sm'
          textAlign='left'
          whiteSpace='nowrap'
        >
          {sortBy(assignments, ['agent_type']).map((assignment) => (
            <Flex direction='row' align='center' gap={4}>
              <Text flex={1} fontWeight={500} color='gray.400'>
                {startCase(assignment.agent_type)}
              </Text>
              <Text flex={2}>{combineFirstNameLastName(assignment.admin)}</Text>
            </Flex>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SidePeekDetails

const getProjectedMoveInCost = (rent: number, moveInDate: Date) => {
  const daysInMonth = getDaysInMonth(moveInDate)
  const daysRenting = daysInMonth - getDate(moveInDate) + 1
  const proration = daysRenting / daysInMonth
  return rent * proration + rent * 2
}
