import moment from 'moment'
import pluralize from 'pluralize'
import { format, parse, isToday, isPast, differenceInDays, isSameDay } from 'date-fns'

export function formatInputDateTime(d: Date | string | undefined | null) {
  if (!d) {
    return ''
  }

  const m = moment(new Date(d))
  return m.format('YYYY-MM-DDTHH:mm:ss')
}

export function formatDate(d: Date | number) {
  if (typeof d === 'number') {
    d = new Date(d)
  }

  const m = moment(d)
  return m.format('YYYY-MM-DD')
}

export const parseDate = (d: string | null | undefined): Date => {
  if (!d) {
    return new Date()
  }

  return parse(d, 'yyyy-MM-dd', new Date())
}

export const formatNullableDateString = (d: string | null | undefined): string => {
  if (!d) {
    return ''
  }

  let formattedDate: string

  try {
    formattedDate = format(parse(d, 'yyyy-MM-dd', new Date()), 'PP')
  } catch (err) {
    formattedDate = format(new Date(d), 'PP')
  }

  return formattedDate
}

export function formatPotentialTourDuration(duration: any) {
  if (typeof duration !== 'number') {
    return ''
  }

  duration = Math.ceil(duration)

  if (duration <= 30) {
    return '30 minutes'
  } else {
    const start = moment().startOf('day')
    const startPlusDuration = moment(start).add(duration, 'minutes')

    // How many minutes do we need to add in order
    // to round up to the nearest 30 minute interval
    const remainder = 30 - (startPlusDuration.minute() % 30)

    const roundedStartPlusDuration = moment(startPlusDuration).add(remainder, 'minutes')

    const numHoursDiff = roundedStartPlusDuration.diff(start, 'hours', true)

    return `${numHoursDiff} ${pluralize('hour', numHoursDiff)}`
  }
}

export const convertDateToUTC = (dateInput: number | string | Date): Date => {
  return new Date(new Date(dateInput).toLocaleString('en-US', { timeZone: 'UTC' }))
}

export const isDateBeforeToday = (dateToCheck: Date | undefined | null): boolean => {
  if (!dateToCheck) {
    return false
  }

  return isPast(dateToCheck) && !isToday(dateToCheck)
}

export const isAfterOrSameDay = (date1: Date, date2: Date): boolean => {
  return differenceInDays(date1, date2) > 0 || isSameDay(date1, date2)
}

export const isBeforeOrSameDay = (date1: Date, date2: Date): boolean => {
  return differenceInDays(date1, date2) < 0 || isSameDay(date1, date2)
}
