import React, { FunctionComponent } from 'react'
import { Link as ReactLink, LinkProps as ReactLinkProps } from 'react-router-dom'
import { Box, Flex, Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

const Link: FunctionComponent<
  React.PropsWithChildren<
    (LinkProps & { useRouter?: false }) | (LinkProps & ReactLinkProps & { useRouter: true })
  >
> = ({ children, useRouter, ...props }) => (
  <ChakraLink
    {...(useRouter ? { as: ReactLink } : {})}
    color='blue.600'
    textDecoration='underline'
    {...props}
  >
    <Flex direction='row' align='center' gap={1}>
      {children}
      {props.isExternal && (
        <Box h={2.5} w={2.5}>
          <ArrowTopRightOnSquareIcon />
        </Box>
      )}
    </Flex>
  </ChakraLink>
)

export default Link
