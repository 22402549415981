import React, { FunctionComponent, useState } from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'
import { Transition } from '@headlessui/react'
import { UploadDocumentPanel } from './UploadDocumentPanel'

type UploadDocumentProps = {
  resourceId: string
  resourceType: string
  typeMap: { [key: string]: string }
  onUpload?: () => Promise<void>
}

export const UploadDocumentButtonWithPopout: FunctionComponent<
  React.PropsWithChildren<UploadDocumentProps>
> = ({ resourceId, resourceType, typeMap, onUpload = async () => {} }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const setClosed = () => setIsOpen(false)

  const slideDownTransitionClasses = {
    show: isOpen,
    enter: 'transform transition ease-in-out duration-300',
    enterFrom: 'translate-y-[-100%]',
    enterTo: 'translate-y-0',
    leave: 'transform transition ease-in-out duration-300',
    leaveFrom: 'translate-y-0',
    leaveTo: 'translate-y-[-100%]'
  }

  return (
    <Stack direction='column' gap='4'>
      <Button onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'close' : 'upload document'}</Button>
      <Box overflow='hidden'>
        <Transition {...slideDownTransitionClasses}>
          <UploadDocumentPanel {...{ resourceId, resourceType, typeMap, onUpload, setClosed }} />
        </Transition>
      </Box>
    </Stack>
  )
}
