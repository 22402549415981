import React, { FC } from 'react'
import { Stack, Text } from '@chakra-ui/react'
import { rentals } from '@homevest/utils'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { CashoutSidebarFragment } from 'graphql/generated'
import Cashouts from 'screens/RentRoll/Details/Cashouts'
import Documents from 'screens/RentRoll/Details/Documents'
import Tenants from 'screens/RentRoll/Details/Tenants'

interface CashoutSidebarProps {
  rental: CashoutSidebarFragment
}

export const Sidebar: FC<React.PropsWithChildren<CashoutSidebarProps>> = ({ rental }) => {
  return (
    <Stack>
      <Text textColor='neutral.900' fontSize='2xl'>
        {rental.portfolio_home?.home?.address?.display_line_1}
      </Text>
      <Text textColor='neutral.700'>{rental.portfolio_home?.home?.address?.display_line_2}</Text>
      <Text textColor='neutral.700'>{rental.property.llc_properties[0]?.llc.name}</Text>
      <div>
        {mapContentToBadge(rentals.RENTAL_PROGRAM_LEASE_TYPES[rental.rental_program_type], {
          dot: true,
          size: 'base'
        })}
      </div>
      <Tenants rental={rental} />
      <Cashouts rental={rental} />
      <Documents rental={rental} />
    </Stack>
  )
}
