import React, { FunctionComponent } from 'react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { StarIcon } from '@heroicons/react/24/solid'
import {
  FindNotesByResourceIdsQuery,
  useFindNotesByResourceIdsQuery,
  useUpdateNotePriorityByNoteIdMutation,
  Note_Priorities_Enum
} from 'graphql/generated'
import { BaseGrid } from 'components/TailwindUIToolkit'
import Error from 'components/Toolkit/Text/Error'

export type NotesGridDataType = FindNotesByResourceIdsQuery['notes'][number]
const { Default, Pinned } = Note_Priorities_Enum

interface GridProps {
  resourceIds: string[]
  filter?: (note: NotesGridDataType) => boolean
}

const Grid: FunctionComponent<React.PropsWithChildren<GridProps>> = ({ resourceIds, filter }) => {
  const [{ data, error }] = useFindNotesByResourceIdsQuery({
    variables: { resourceIds }
  })

  const [_, executeMutation] = useUpdateNotePriorityByNoteIdMutation()

  if (error) {
    return <Error>{error.message}</Error>
  }

  let notes = data?.notes

  if (filter) {
    notes = notes?.filter(filter)
  }

  if (!notes) {
    return <Error>notes not found</Error>
  }

  const columns: ColDef<NotesGridDataType>[] = [
    {
      headerName: 'Note',
      field: 'note',
      flex: 5,
      cellStyle: {
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        alignItems: 'start',
        paddingTop: '16px',
        paddingBottom: '16px'
      }
    },
    {
      headerName: 'type',
      field: 'resource_type',
      valueFormatter: (v) => startCase(v.value),
      flex: 2
    },
    {
      headerName: 'admin',
      field: 'admin',
      valueFormatter: (v) => `${v.value.first_name} ${v.value.last_name}`,
      flex: 2
    },
    {
      headerName: 'date',
      field: 'created_at',
      sort: 'desc',
      sortIndex: 1,
      valueFormatter: (v) => format(new Date(v.value), 'PP'),
      flex: 2
    },
    {
      headerName: '',
      field: 'priority',
      flex: 1,
      sort: 'desc',
      sortIndex: 0,
      cellRenderer: (v: ICellRendererParams<NotesGridDataType>) => (
        <StarIcon
          onClick={() =>
            executeMutation({
              noteId: v.data?.id,
              priority: v.data?.priority === Pinned ? Default : Pinned
            })
          }
          className={`h-5 w-5 cursor-pointer hover:opacity-75 ${
            v.data?.priority === 'pinned' ? `fill-yellow-300` : `fill-slate-300`
          }`}
        />
      )
    }
  ]

  return <BaseGrid<NotesGridDataType> columns={columns} rowData={notes} />
}

export default Grid
